import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../providers';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
 
@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.html'
})

export class ForgotPasswordPage implements OnInit {

    loading = false;
    form: any;
 
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder) {

        }
 
    ngOnInit() {
        this.form = this.formBuilder.group({
            email: ['', Validators.required]
        });
    }

  send(email){
    var statuEmail = this.form.controls.email.status
    if(statuEmail == 'INVALID'){

      this.toastr.error("L'adresse email n'est pas correct.");

    } else if(this.form.status == 'VALID'){

      this.loading = true;

      email = this.form.controls.email.value.toLowerCase();

      let data = {email: email};

      this.authService.passwordRecovery(data).subscribe((result) => {
        if(result.success == true){
    		  this.router.navigate(['/reset/password/check/'+result.userId+'/'+email]);
    		  this.loading = false;
        } else {
          this.loading = false;
        }
      }, err => {
      	this.toastr.error("Un problème a été détecté, merci de re-essayer plus tard ou de nous contacter directement.");
      });
    }
  }

}
