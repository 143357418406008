import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../providers';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
 
@Component({
	selector: 'app-check-reset',
	templateUrl: './check-reset.html'
})

export class CheckResetPage implements OnInit {

    loading = false;
    form: any;
    email: string;
 
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder) {

        }
 
    ngOnInit() {

		this.route.params.subscribe(params => {
			if(params.id != undefined && params.email != undefined){
		        this.form = this.formBuilder.group({
		        	id: [params.id, Validators.required],
		            code: ['', Validators.required]
		        });
		        this.email = params.email;
			} else {
				this.loading = false;
				this.router.navigate(['/404']);
			}
		});
    }

    send(data) {
        if(
            data.value.code != null && data.value.code != ''
        ){
            this.loading = true;
            this.authService.checkReset(data.value).subscribe(result => {
            	this.loading = false;
            	if(result.success){
				  this.router.navigate(['/reset/password/new/'+data.value.id+'/'+this.email+'/'+data.value.code]);
            	} else {
            		this.toastr.error('Le code est incorrect.');
            	}
            }, err => {
            	this.loading = false;
                this.toastr.error('Il y a eu une erreur, veuillez re-essayer plus tard.');
            });
        } else {
        	this.loading = false;
            this.toastr.error("Vous devez renseigner le code que vous avez reçu par email.");
        }

    }

}
