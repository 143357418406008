import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Config } from '../../app.config';
import { AuthService } from '../auth';
import { map } from 'rxjs/operators';

@Injectable()
export class ForumThreadService {

	constructor(
		private http: Http,
		private config: Config,
		private authService: AuthService
	) {}


    public getAllForumThread() {

		let url = this.config.apiUrl + '/api/forumThread';
		let token = this.authService.getToken();

		let headers = new Headers({"Authorization": token, "Content-Type": "application/json"});
		let options  = new RequestOptions({ headers: headers });

		return this.http.get(url, options).pipe(
		  map((result) => {
		    return result.json();
		  })
		)
	}	

	public getOneForumThread(id) {

		let url = this.config.apiUrl + '/api/forumThread/' + id;
		let token = this.authService.getToken();

		let headers = new Headers({"Authorization": token, "Content-Type": "application/json"});
		let options  = new RequestOptions({ headers: headers });

		return this.http.get(url, options).pipe(
		  map((result) => {
		    return result.json();
		  }) 
		)
	}	

	public CreateNewForumThread(data) {

		let url = this.config.apiUrl + '/api/forumThread';
		let token = this.authService.getToken();

		let headers = new Headers({"Authorization": token, "Content-Type": "application/json"});
		let options  = new RequestOptions({ headers: headers });

		return this.http.post(url, data, options).pipe(
		  map((result) => {
		    return result.json();
		  })
		)
	}	

}
