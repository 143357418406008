import { Component, OnInit, ViewChild } from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { CompareForm } from '../../../includes/compare-form/compare-form';
import { ToastrService } from 'ngx-toastr';

// Providers
import { ToolsService } from '../../../providers';

@Component({
  	selector: 'app-rates-and-need-per-customer',
  	templateUrl: './rates-and-need-per-customer.html',
  	providers: [  ],
  	animations: [  		
        // Déploiement tableaux
        trigger('expand', [
            transition(':enter', [
                style({
                	height:'0',
                	overflow:'hidden'
                }),
                animate(200, style({
                	height:'*',
                	overflow:'hidden'
                })) 
            ])
        ]),
  	]
})
export class RatesAndNeedPerCustomerPage implements OnInit {

  	public loading = false;

    // Retour API
    objectKeys = Object.keys;

    data: any;
    compareData: any;

    // Formulaire de comparaison
    @ViewChild(CompareForm) compareForm;
    subscription: any;

  	// Toggle comparaison
  	comparison : boolean = false;

    dataReady: boolean = true;

  	constructor(
      private toolsService: ToolsService,
      private toastr: ToastrService
    ) { 
  
  	}
  
  	ngOnInit() {
    	this.loading = true;

      this.subscription = this.compareForm.getComparisonEmitter().subscribe(item => {
        this.toolsService.compare("ratesNeedsByClient", item.year, item.structure, item.volume).subscribe(resultCompare => {
          if(resultCompare.success && (item.year || item.structure || item.volume)) {
            this.compareData = resultCompare.data;
            this.compareForm.comparison = true;
          }
          else {
            this.compareData = "";
            this.compareForm.comparison = false;
            if(item.year || item.structure || item.volume) {
                this.toastr.error("Aucune comparaison à afficher");
            }
          }
        });
      });

      this.runData();
    }

    runData() {
      this.toolsService.me("ratesNeedsByClient", 2018).subscribe(result => {
        if(result.success){
          this.data = result.data;
          this.comparison = this.compareForm.comparison;
        } else {
          this.dataReady = false;
        }
      });
    }
}
