import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Config } from '../../app.config';
import { AuthService } from '../auth';
import { map } from 'rxjs/operators';

@Injectable()
export class MyCenterService {

	constructor(
		private http: Http,
		private config: Config,
		private authService: AuthService
	) {

	}

	public myCenterName() {

		let url = this.config.apiUrl + '/api/myCenter/name/center';
		let token = this.authService.getToken();

		let headers = new Headers({"Authorization": token, "Content-Type": "application/json"});
		let options  = new RequestOptions({ headers: headers });

		return this.http.get(url, options).pipe(
		  map((result) => {
		    return result.json();
		  })
		)
	}

	public myCenter(year) {

		let url = this.config.apiUrl + '/api/myCenter/year/' + year;
		let token = this.authService.getToken();

		let headers = new Headers({"Authorization": token, "Content-Type": "application/json"});
		let options  = new RequestOptions({ headers: headers });

		return this.http.get(url, options).pipe(
		  map((result) => {
		    return result.json();
		  })
		)
	}

	public myDraftCenter(year) {

		let url = this.config.apiUrl + '/api/myCenter/draft/year/' + year;
		let token = this.authService.getToken();

		let headers = new Headers({"Authorization": token, "Content-Type": "application/json"});
		let options  = new RequestOptions({ headers: headers });

		return this.http.get(url, options).pipe(
		  map((result) => {
		    return result.json();
		  })
		)
	}

	public createOrUpdate(data) {

		let url = this.config.apiUrl + '/api/myCenter';
		let token = this.authService.getToken();

		let headers = new Headers({"Authorization": token, "Content-Type": "application/json"});
		let options  = new RequestOptions({ headers: headers });

		return this.http.put(url, data, options).pipe(
		  map((result) => {
		    return result.json();
		  })
		)
	}	

	public createOrUpdateDraft(data) {

		let url = this.config.apiUrl + '/api/myCenter/draft';
		let token = this.authService.getToken();

		let headers = new Headers({"Authorization": token, "Content-Type": "application/json"});
		let options  = new RequestOptions({ headers: headers });

		return this.http.put(url, data, options).pipe(
		  map((result) => {
		    return result.json();
		  })
		)
	}
}
