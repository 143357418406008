import { Component, OnInit, ViewChild } from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { CompareForm } from '../../../includes/compare-form/compare-form';
import * as ChartsJS from '../../../../assets/libraries/chart.bundle.min';
import { ToastrService } from 'ngx-toastr';

// Providers
import { ToolsService } from '../../../providers';

@Component({
  	selector: 'app-synthesis',
  	templateUrl: './synthesis.html',
  	providers: [  ],
  	animations: [  		
        // Déploiement tableaux
        trigger('expand', [
            transition(':enter', [
                style({
                	height:'0',
                	overflow:'hidden'
                }),
                animate(200, style({
                	height:'*',
                	overflow:'hidden'
                })) 
            ])
        ]),
        // Déploiement charts
        trigger('expandChart', [
            transition(':enter', [
                style({height:0, overflow:'hidden', 'margin-bottom':0})
            ]),
            state('false', style({
                height: 0,
                overflow:'hidden',
                'margin-bottom': 0,
            })),
            state('true', style({
                height: '*',
                overflow:'visible',
                'margin-bottom': '30px',
            })),
            transition('* => true', [
                style({height:0, overflow:'hidden'}),
                animate(200, style({height:'*', overflow:'visible', 'margin-bottom':'30px'}))
            ])
        ]),
  	]
})
export class SynthesisPage implements OnInit {

  	public loading = false;

  	// Retour API
    objectKeys = Object.keys;

  	data: any;
  	compareData: any;

  	// Fin retour API

    // Formulaire de comparaison
    @ViewChild(CompareForm) compareForm;
    subscription: any;

  	// Toggle comparaison
  	comparison : boolean = false;

  	/* Charts variables */
    doughnutChartTooltips : any = [];
    etpDoughnutChartTooltips : any = [];
    barChartTooltips : any = [];
  	optionsDoughnut : any = [];
  	optionsEtpDoughnut : any = [];
  	typeDoughnut : any = '';
  	optionsBarVertical : any = [];
  	typeBarVertical : any = '';
  	optionsBarHorizontal : any = [];
  	typeBarHorizontal : any = '';
  	// Doughnut Keys chart
  	keysChart_elem : any = '';
  	keysChartComparison_elem : any = '';
  	keysChartData : any = [];
  	keysChartComparisonData : any = [];
  	keysChart : any = '';
  	keysChartComparison : any = '';
  	// Doughnut Charges chart
  	chargesDoughnutChart_elem : any = '';
  	chargesDoughnutChartComparison_elem : any = '';
  	chargesDoughnutChartData : any = [];
  	chargesDoughnutChartComparisonData : any = [];
  	chargesDoughnutChart : any = '';
  	chargesDoughnutChartComparison : any = '';
  	// Vertical bar Unit chart
  	unitChart_elem : any = '';
  	unitChartData : any = [];
  	unitChartDataComparison : any = [];
  	unitChart : any = '';
  	// Vertical bar Total chart
  	totalChart_elem : any = '';
  	totalChartData : any = [];
  	totalChartDataComparison : any = [];
  	totalChart : any = '';
  	// Charges chart
  	chargesChart_elem : any = '';
  	chargesChartDataHT : any = [];
  	chargesChartDataHTComparison : any = [];
  	chargesChartDataTTC : any = [];
  	chargesChartDataTTCComparison : any = [];
  	dataChargesChartHT : any = [];
  	dataChargesChart : any = [];
  	chargesChart : any = '';
  	// Amortization chart
  	amortizationChart_elem : any = '';
  	amortizationChartDataHT : any = [];
  	amortizationChartDataHTComparison : any = [];
  	amortizationChartDataTTC : any = [];
  	amortizationChartDataTTCComparison : any = [];
  	dataAmortizationChartHT : any = [];
  	dataAmortizationChart : any = [];
  	amortizationChart : any = '';

  	// Charts toggle buttons
  	chargesTTC : boolean = false;
  	amortizationTTC : boolean = false;

  	dataReady: boolean = true;

  	constructor(
      private toolsService: ToolsService,
      private toastr: ToastrService
    ) { 
  
  	}
  
  	ngOnInit() {
      this.loading = true;

      // Lorsque la comparaison est déclenchée dans le composant compareForm, on trigger la fonction compareCharts()
      this.subscription = this.compareForm.getComparisonEmitter().subscribe(item => {
        this.toolsService.compare("synthesis", item.year, item.structure, item.volume).subscribe(resultCompare => {
          if(resultCompare.success && (item.year || item.structure || item.volume)) {
            this.compareData = resultCompare.data;
          	this.compareForm.comparison = true;
            this.compareCharts();
          }
          else {
          	this.compareData = "";
          	this.compareForm.comparison = false;
          	if(item.year || item.structure || item.volume) {
	          	this.toastr.error("Aucune comparaison à afficher");
          	}
          	this.initialCharts();
          }
        });
      });

      this.runData();
  	}

    runData() {
      this.toolsService.me("synthesis", 2018).subscribe(result => {
        if(result.success){
          this.data = result.data;
          this.comparison = this.compareForm.comparison;
          // Création des graphiques initiaux
          this.initialCharts();
        } else {
        	this.dataReady = false;
        }
      });
    }

  	makeChart(chartElement, type, data, options) {
  		return new ChartsJS(chartElement, {
		    type: type,
		    data: data,
		    options: options
		  });
  	}

  	updateChart(chart, dataReplace, dataReplaceComparison) {
  		chart.data.datasets[0].data = dataReplace;

  		if(chart.data.datasets.length > 1) {
	  		chart.data.datasets[1].data = dataReplaceComparison;
	  	}

  		chart.update();
  	}

  	addDataset(chart, dataset) {

  		if(chart.data.datasets.length < 2) {
			chart.data.datasets.push(dataset);
			chart.update();
		}
  	}


  	/* 
  	 *  Cette fonction gère la création des graphiques initiaux
  	 */
  	initialCharts() {

		/* DOUGHNUT CHARTS */
        // DOUGHNUT OPTIONS
        this.doughnutChartTooltips = {
            callbacks: {
                label: function(tooltipItem, data) {
                    return " " + data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',') + "%";
                }
            },
            backgroundColor: '#000',
            titleFontSize: 0,
            titleFontColor: '#0066ff',
            bodyFontColor: '#fff',
            bodyFontSize: 12,
            displayColors: true
        }
        this.etpDoughnutChartTooltips = {
            callbacks: {
                label: function(tooltipItem, data) {
                    return " " + data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',') + " ETP";
                }
            },
            backgroundColor: '#000',
            titleFontSize: 0,
            titleFontColor: '#0066ff',
            bodyFontColor: '#fff',
            bodyFontSize: 12,
            displayColors: true
        }

        this.optionsDoughnut = {
        	legend: {
	        	display: false,
	        },
	        aspectRatio: 1,
	        cutoutPercentage: 68,
            tooltips: this.doughnutChartTooltips
        }
        this.optionsEtpDoughnut = {
        	legend: {
	        	display: false,
	        },
	        aspectRatio: 1,
	        cutoutPercentage: 68,
            tooltips: this.etpDoughnutChartTooltips
        }
		this.typeDoughnut = 'doughnut';

        // DOUGHNUT KEYS CHART
	    this.keysChart_elem = document.getElementById("doughnut_keys") as HTMLCanvasElement;
	    this.keysChartData = {
			datasets: [{
		       	data: [
		       		this.data.tonnageRepartition.greatDish.percent,
		       		this.data.tonnageRepartition.smallDish.percent,
		       		this.data.tonnageRepartition.form.percent,
		       		this.data.tonnageRepartition.driedFolded.percent,
		       		this.data.tonnageRepartition.dried.percent,
		       		this.data.tonnageRepartition.residentsLaundry.percent
		       	],
		        backgroundColor: ['#41bbe7', '#ff003c', '#68cbb5', '#22216d', '#f000ff', '#ffd800'],
		        borderWidth: 0
		  	}],
		   	labels: [
		   		'Encadrement',
		   		'Maintenance',
		        'Personnel de remplacement',
		        'Personnel de production',
		        'Personnel affecté au transport',
		        'Personnel administratif'
		   	]
		}
		this.keysChart = this.makeChart(this.keysChart_elem, this.typeDoughnut, this.keysChartData, this.optionsDoughnut);

		// DOUGHNUT CHARGES CHART
	    this.chargesDoughnutChart_elem = document.getElementById("doughnut_charges") as HTMLCanvasElement;
        this.chargesDoughnutChartData = {
		    datasets: [{
			    data: [
			    	this.data.staffCharges.items.framing.value,
			    	this.data.staffCharges.items.maintenance.value,
			    	this.data.staffCharges.items.replacingStaff.value,
			    	this.data.staffCharges.items.productionStaff.value,
			    	this.data.staffCharges.items.transportStaff.value,
			    	this.data.staffCharges.items.administrativeStaff.value
			    ],
		        backgroundColor: ['#41bbe7', '#ff003c', '#68cbb5', '#22216d', '#f000ff', '#ffd800'],
		        borderWidth: 0
			}],
			labels: [
				'Encadrement',
				'Maintenance',
			    'Personnel de remplacement',
			    'Personnel de production',
			    'Personnel affecté au transport',
			    'Personnel administratif'
			]
		}
		this.chargesDoughnutChart = this.makeChart(this.chargesDoughnutChart_elem, this.typeDoughnut, this.chargesDoughnutChartData, this.optionsEtpDoughnut);

		/* VERTICAL BAR CHARTS */
		// VERTICAL BAR CHART OPTIONS
        this.barChartTooltips = {
            callbacks: {
                label: function(tooltipItem, data) {
                    return " " + data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',') + " €";
                }
            },
            backgroundColor: '#000',
            titleFontSize: 0,
            titleFontColor: '#0066ff',
            bodyFontColor: '#fff',
            bodyFontSize: 12,
            displayColors: true
        }

        this.optionsBarVertical = {
		    legend: {
		    	display: false
		    },
		    scales: {
		    	xAxes: [{	        		
					barThickness: 25,
		            gridLines: {
		                display:false
		            },
      				ticks: {
		                fontSize: 15,
		                fontColor: '#a1a1a1',
		                fontFamily: 'Muli'
      				}
		    	}],
		        yAxes: [{
		            ticks: {
		                beginAtZero:true,
		                fontSize: 12,
		                fontColor: '#a1a1a1',
		                fontFamily: 'Muli'
		            },
		            gridLines: {
		                display:false
		            }
		        }]
		    },
            tooltips: this.barChartTooltips
		}
		this.typeBarVertical = 'bar';

		// UNIT BAR CHART
	    this.unitChart_elem = document.getElementById("chart_unit") as HTMLCanvasElement;
		this.unitChartData = {
		    labels: ["Eau", "Énergie (gaz)", ["Produits", "lessiviels"]],
		    datasets: [{
		        data: [
		        	this.data.consumptions.water.unitPrice,
		        	this.data.consumptions.energy.unitPrice,
		        	this.data.consumptions.products.unitPrice
		        ],
		        backgroundColor: '#41bbe7',
		    }]
		}
		this.unitChart = this.makeChart(this.unitChart_elem, this.typeBarVertical, this.unitChartData, this.optionsBarVertical);

		// TOTAL BAR CHART
	    this.totalChart_elem = document.getElementById("chart_total") as HTMLCanvasElement;
		this.totalChartData = {
		    labels: ["Eau", "Énergie (gaz)", ["Produits", "lessiviels"]],
		    datasets: [{
		        data: [
		        	this.data.consumptions.water.amount,
		        	this.data.consumptions.energy.amount,
		        	this.data.consumptions.products.amount
		        ],
		        backgroundColor: '#41bbe7',
		    }]
		}
		this.totalChart = this.makeChart(this.totalChart_elem, this.typeBarVertical, this.totalChartData, this.optionsBarVertical);


		/* HORIZONTAL BAR CHARTS */
		// HORIZONTAL BAR CHART OPTIONS
        this.optionsBarHorizontal = {
		    legend: {
		    	display: false
		    },
		    scales: {
		    	yAxes: [{	        		
					barThickness: 20,
		            gridLines: {
		                display:false
		            },
		            afterFit: function(scaleInstance) {
        				scaleInstance.width = 170;
      				},
      				ticks: {
		                fontSize: 13,
		                fontColor: '#a1a1a1',
		                fontFamily: 'Muli'
      				}
		    	}],
		        xAxes: [{
		            ticks: {
		                beginAtZero:true,
		                fontSize: 12,
		                fontColor: '#a1a1a1',
		                fontFamily: 'Muli'
		            },
		            gridLines: {
		                display:false
		            },
		            position: 'top'
		        }]
		    },
		    animation: {
		    	duration: 500
		    },
            tooltips: this.barChartTooltips
		}
		this.typeBarHorizontal = 'horizontalBar';

		// CHARGES BAR CHART
	    this.chargesChart_elem = document.getElementById("chart_charges") as HTMLCanvasElement;
	    this.chargesChartDataHT = [
	    	this.data.otherCharges.HT.laundryPurchase,
			this.data.otherCharges.HT.electricity,
			this.data.otherCharges.HT.vehiclesRenting,
			this.data.otherCharges.HT.factorySupplies,
			this.data.otherCharges.HT.fuel,
			this.data.otherCharges.HT.administrativeSupplies,
			this.data.otherCharges.HT.spareParts,
			this.data.otherCharges.HT.smallMaterial,
			this.data.otherCharges.HT.insurances,
			this.data.otherCharges.HT.externalServices,
			this.data.otherCharges.HT.buildingMaintenance,
			this.data.otherCharges.HT.otherPayments,
			this.data.otherCharges.HT.cleaning
	    ];
	    this.chargesChartDataTTC = [
	    	this.data.otherCharges.TTC.laundryPurchase,
			this.data.otherCharges.TTC.electricity,
			this.data.otherCharges.TTC.vehiclesRenting,
			this.data.otherCharges.TTC.factorySupplies,
			this.data.otherCharges.TTC.fuel,
			this.data.otherCharges.TTC.administrativeSupplies,
			this.data.otherCharges.TTC.spareParts,
			this.data.otherCharges.TTC.smallMaterial,
			this.data.otherCharges.TTC.insurances,
			this.data.otherCharges.TTC.externalServices,
			this.data.otherCharges.TTC.buildingMaintenance,
			this.data.otherCharges.TTC.otherPayments,
			this.data.otherCharges.TTC.cleaning
		];
		this.dataChargesChart =  {
		    labels: ["Achats de linge", "Électricité", "Location véhicules", "Fournitures usine", "Carburant", ["Fournitures", "administratives"], "Pièces détachées", "Petit matériel", "Assurances", "Services extérieurs", "Entretien bâtiment", "Autres rémunérations", "Prestation ménage"],
		    datasets: [{
		        data: this.chargesChartDataHT,
		        backgroundColor: '#41bbe7',
		    }]
		}
		this.chargesChart = this.makeChart(this.chargesChart_elem, this.typeBarHorizontal, this.dataChargesChart, this.optionsBarHorizontal);

		// AMORTIZATION BAR CHART
	    this.amortizationChart_elem = document.getElementById("chart_amortization") as HTMLCanvasElement;
	    this.amortizationChartDataHT = [
	    	this.data.amortization.HT.beforeSorting,
			this.data.amortization.HT.washingRolls,
			this.data.amortization.HT.sorting,
			this.data.amortization.HT.tunnelsSpinDrying,
			this.data.amortization.HT.residentsLaundry,
			this.data.amortization.HT.productsCentral,
			this.data.amortization.HT.cleanHandling,
			this.data.amortization.HT.GP,
			this.data.amortization.HT.PP,
			this.data.amortization.HT.shape,
			this.data.amortization.HT.conveying,
			this.data.amortization.HT.folding,
			this.data.amortization.HT.filming,
			this.data.amortization.HT.bagger,
			this.data.amortization.HT.conveyor,
			this.data.amortization.HT.gpao,
			this.data.amortization.HT.buyingRolls,
			this.data.amortization.HT.gti,
			this.data.amortization.HT.miscellaneous
	    ];
	    this.amortizationChartDataTTC = [	    
	    	this.data.amortization.TTC.beforeSorting,
			this.data.amortization.TTC.washingRolls,
			this.data.amortization.TTC.sorting,
			this.data.amortization.TTC.tunnelsSpinDrying,
			this.data.amortization.TTC.residentsLaundry,
			this.data.amortization.TTC.productsCentral,
			this.data.amortization.TTC.cleanHandling,
			this.data.amortization.TTC.GP,
			this.data.amortization.TTC.PP,
			this.data.amortization.TTC.shape,
			this.data.amortization.TTC.conveying,
			this.data.amortization.TTC.folding,
			this.data.amortization.TTC.filming,
			this.data.amortization.TTC.bagger,
			this.data.amortization.TTC.conveyor,
			this.data.amortization.TTC.gpao,
			this.data.amortization.TTC.buyingRolls,
			this.data.amortization.TTC.gti,
			this.data.amortization.TTC.miscellaneous
		];
		this.dataAmortizationChart =  {
		    labels: ["Avant tri", "Lavage rolls", "Tri", "Tunnels + Essorage", "Linge des résidents", "Centrale produits", "Manut propre", "GP", "PP", "Forme", "Convoyage", "Pliage", "Filmage", "Ensacheuse", "Convoyeur", "Informatique GPAO", "Achat rolls", "Informatique GTI", "Lot bâtiment et divers"],
		    datasets: [{
		        data: this.amortizationChartDataHT,
		        backgroundColor: '#41bbe7',
		    }]
		}
		this.amortizationChart = this.makeChart(this.amortizationChart_elem, this.typeBarHorizontal, this.dataAmortizationChart, this.optionsBarHorizontal);
  	}


  	/*
  	 *  Cette fonction gère la création des graphiques comparatifs
  	 */
  	compareCharts() {

		// DOUGHNUT KEYS CHART COMPARISON
	    this.keysChartComparison_elem = document.getElementById("doughnut_keys_compare") as HTMLCanvasElement;
	    this.keysChartComparisonData = {
			datasets: [{		       	
		       	data: [
		       		this.compareData.tonnageRepartition.greatDish.percent,
		       		this.compareData.tonnageRepartition.smallDish.percent,
		       		this.compareData.tonnageRepartition.form.percent,
		       		this.compareData.tonnageRepartition.driedFolded.percent,
		       		this.compareData.tonnageRepartition.dried.percent,
		       		this.compareData.tonnageRepartition.residentsLaundry.percent
		       	],
		        backgroundColor: ['#41bbe7', '#ff003c', '#68cbb5', '#22216d', '#f000ff', '#ffd800'],
		        borderWidth: 0
		  	}],
		   	labels: [
		   		'Encadrement',
		   		'Maintenance',
		        'Personnel de remplacement',
		        'Personnel de production',
		        'Personnel affecté au transport',
		        'Personnel administratif'
		   	]
		}
		this.keysChartComparison = this.makeChart(this.keysChartComparison_elem, this.typeDoughnut, this.keysChartComparisonData, this.optionsDoughnut);

		// DOUGHNUT CHARGES CHART COMPARISON
	    this.chargesDoughnutChartComparison_elem = document.getElementById("doughnut_charges_compare") as HTMLCanvasElement;
	    this.chargesDoughnutChartComparisonData = {
			datasets: [{
		       	data: [
                    this.compareData.staffCharges.items.framing.value,
                    this.compareData.staffCharges.items.maintenance.value,
                    this.compareData.staffCharges.items.replacingStaff.value,
                    this.compareData.staffCharges.items.productionStaff.value,
                    this.compareData.staffCharges.items.transportStaff.value,
                    this.compareData.staffCharges.items.administrativeStaff.value
			    ],
		        backgroundColor: ['#41bbe7', '#ff003c', '#68cbb5', '#22216d', '#f000ff', '#ffd800'],
		        borderWidth: 0
		  	}],
		   	labels: [
		   		'Encadrement',
		   		'Maintenance',
		        'Personnel de remplacement',
		        'Personnel de production',
		        'Personnel affecté au transport',
		        'Personnel administratif'
		   	]
		}
		this.chargesDoughnutChartComparison = this.makeChart(this.chargesDoughnutChartComparison_elem, this.typeDoughnut, this.chargesDoughnutChartComparisonData, this.optionsEtpDoughnut);

		// UNIT BAR CHART COMPARISON
		this.unitChartDataComparison = {
			data: [
		       	this.compareData.consumptions.water.unitPrice,
		       	this.compareData.consumptions.energy.unitPrice,
		       	this.compareData.consumptions.products.unitPrice
		    ],
		    backgroundColor: '#ff2c59'
		};
		this.addDataset(this.unitChart, this.unitChartDataComparison);

		// TOTAL BAR CHART COMPARISON
		this.totalChartDataComparison = {
			data: [
		       	this.compareData.consumptions.water.amount,
		       	this.compareData.consumptions.energy.amount,
		       	this.compareData.consumptions.products.amount
		    ],
		    backgroundColor: '#ff2c59'
		};
		this.addDataset(this.totalChart, this.totalChartDataComparison);

		// CHARGES BAR CHART
		this.chargesChartDataHTComparison = [
  			this.compareData.otherCharges.HT.laundryPurchase,
  			this.compareData.otherCharges.HT.electricity,
  			this.compareData.otherCharges.HT.vehiclesRenting,
  			this.compareData.otherCharges.HT.factorySupplies,
  			this.compareData.otherCharges.HT.fuel,
  			this.compareData.otherCharges.HT.administrativeSupplies,
  			this.compareData.otherCharges.HT.spareParts,
  			this.compareData.otherCharges.HT.smallMaterial,
  			this.compareData.otherCharges.HT.insurances,
  			this.compareData.otherCharges.HT.externalServices,
  			this.compareData.otherCharges.HT.buildingMaintenance,
  			this.compareData.otherCharges.HT.otherPayments,
  			this.compareData.otherCharges.HT.cleaning
		];
		this.chargesChartDataTTCComparison = [
  			this.compareData.otherCharges.TTC.laundryPurchase,
  			this.compareData.otherCharges.TTC.electricity,
  			this.compareData.otherCharges.TTC.vehiclesRenting,
  			this.compareData.otherCharges.TTC.factorySupplies,
  			this.compareData.otherCharges.TTC.fuel,
  			this.compareData.otherCharges.TTC.administrativeSupplies,
  			this.compareData.otherCharges.TTC.spareParts,
  			this.compareData.otherCharges.TTC.smallMaterial,
  			this.compareData.otherCharges.TTC.insurances,
  			this.compareData.otherCharges.TTC.externalServices,
  			this.compareData.otherCharges.TTC.buildingMaintenance,
  			this.compareData.otherCharges.TTC.otherPayments,
  			this.compareData.otherCharges.TTC.cleaning
		];
		var chargesChartDataHTDatasetComparison = {
			data: this.chargesChartDataHTComparison,
			backgroundColor: '#ff2c59',
		};
		this.addDataset(this.chargesChart, chargesChartDataHTDatasetComparison);

		// AMORTIZATION BAR CHART
		this.amortizationChartDataHTComparison = [
  				this.compareData.amortization.HT.beforeSorting,
  				this.compareData.amortization.HT.washingRolls,
  				this.compareData.amortization.HT.sorting,
  				this.compareData.amortization.HT.tunnelsSpinDrying,
  				this.compareData.amortization.HT.residentsLaundry,
  				this.compareData.amortization.HT.productsCentral,
  				this.compareData.amortization.HT.cleanHandling,
  				this.compareData.amortization.HT.GP,
  				this.compareData.amortization.HT.PP,
  				this.compareData.amortization.HT.shape,
  				this.compareData.amortization.HT.conveying,
  				this.compareData.amortization.HT.folding,
  				this.compareData.amortization.HT.filming,
  				this.compareData.amortization.HT.bagger,
  				this.compareData.amortization.HT.conveyor,
  				this.compareData.amortization.HT.gpao,
  				this.compareData.amortization.HT.buyingRolls,
  				this.compareData.amortization.HT.gti,
  				this.compareData.amortization.HT.miscellaneous
  			];
		this.amortizationChartDataTTCComparison = [
  				this.compareData.amortization.TTC.beforeSorting,
  				this.compareData.amortization.TTC.washingRolls,
  				this.compareData.amortization.TTC.sorting,
  				this.compareData.amortization.TTC.tunnelsSpinDrying,
  				this.compareData.amortization.TTC.residentsLaundry,
  				this.compareData.amortization.TTC.productsCentral,
  				this.compareData.amortization.TTC.cleanHandling,
  				this.compareData.amortization.TTC.GP,
  				this.compareData.amortization.TTC.PP,
  				this.compareData.amortization.TTC.shape,
  				this.compareData.amortization.TTC.conveying,
  				this.compareData.amortization.TTC.folding,
  				this.compareData.amortization.TTC.filming,
  				this.compareData.amortization.TTC.bagger,
  				this.compareData.amortization.TTC.conveyor,
  				this.compareData.amortization.TTC.gpao,
  				this.compareData.amortization.TTC.buyingRolls,
  				this.compareData.amortization.TTC.gti,
  				this.compareData.amortization.TTC.miscellaneous
  			];
		var amortizationChartDataHTDatasetComparison = {
			data: this.amortizationChartDataHTComparison,
			backgroundColor: '#ff2c59',
		};
		this.addDataset(this.amortizationChart, amortizationChartDataHTDatasetComparison);

		// RESET HORIZONTAL BAR CHARTS TO HT
		this.updateChart(this.chargesChart, this.chargesChartDataHT, this.chargesChartDataHTComparison);
		this.chargesTTC = false;
		this.updateChart(this.amortizationChart, this.amortizationChartDataHT, this.amortizationChartDataHTComparison);
  		this.amortizationTTC = false;
  	}
}
