import { Component, OnInit } from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';

import { NgModule, LOCALE_ID } from '@angular/core';

import { registerLocaleData } from '@angular/common';

import localeFr from '@angular/common/locales/fr';

import { Router, ActivatedRoute, Params } from '@angular/router';

registerLocaleData(localeFr);

// Providers
import { 
    ForumCategoryService,
    ForumThreadService,
    ForumMessageService
 } from '../../../../providers';

@Component({
  	selector: 'app-thread',
    templateUrl: './thread.html',
    providers: [ {provide: LOCALE_ID, useValue: "fr-FR" } ],
    animations: [       
        // Déploiement formulaire d'ajout
        trigger('expand', [
            transition(':enter', [
                style({
                    height:'0',
                    overflow:'hidden'
                }),
                animate(200, style({
                    height:'*',
                    overflow:'hidden'
                })) 
            ]),
            transition(':leave', [
                style({
                    height:'*',
                    overflow:'hidden'
                }),
                animate(200, style({
                    height:'0',
                    overflow:'hidden'
                })) 
            ])
        ])
    ]
})
export class ThreadPage implements OnInit {

      public loading = false;
      
      id: any;
      forumThread: any;
      forumMessage: any;

      forumMessageForm: any;

      dialog: boolean = false;

      itemFavorite: any;

      itemLike: any;

      // For route to get all messages
      paramData: any;


  	constructor(
        private forumCategoryService: ForumCategoryService,
        private forumThreadService: ForumThreadService,
        private forumMessageService: ForumMessageService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router
  	) { 

      }
      
  
  	ngOnInit() {
        this.loading = true;

        this.id = this.route.snapshot.paramMap.get('id');

        this.formMessage();

        this.getForumThread();
        this.getForumMessages();
    } 

    formMessage() {
        this.forumMessageForm = this.formBuilder.group({
            message: ['', Validators.required],
            forumThreadId: [this.id, Validators.required],

        });
    }

    getForumThread() {

        this.id = this.route.snapshot.paramMap.get('id');

        this.forumThreadService.getOneForumThread(this.id).subscribe(result => {
            if(result.success){
                this.forumThread = result.data;
                console.log(this.forumThread);
            }

        });
    }


    getForumMessages() {

        this.id = this.route.snapshot.paramMap.get('id');

        this.forumMessageService.getForumMessage(this.id, this.paramData).subscribe(result => {
            if(result.success){
                this.forumMessage = result.data;
                console.log(this.forumMessage);
            }

        });
    }

    //Favorite functionality on thread
    addFavorite(item) {
        this.itemFavorite = item+'-add';
        this.postFavoriteToggle();
    }

    removeFavorite(item) {
        this.itemFavorite = item+'-remove';
        this.postFavoriteToggle();
    }
    

    postFavoriteToggle() {

        let favorite = {
            favoriteId: this.itemFavorite
        }

        this.forumCategoryService.getAllForumCategory(favorite).subscribe(result => {
            if(result){
                this.getForumThread(); 
            }
        });
    }

    //Like functionality on messages
    addLike(item) {
        this.itemLike = item+'-add';
        this.postLikeToggle();
    }

    removeLike(item) {
        this.itemLike = item+'-remove';
        this.postLikeToggle();
    }

    postLikeToggle() {

        let like = {
            likeId: this.itemLike
        }

        this.id = this.route.snapshot.paramMap.get('id');

        this.forumMessageService.getForumMessage(this.id, like).subscribe(result => {
            if(result){
                this.getForumMessages(); 
            }
        });
    }

    openDialog() {
    	this.dialog = true;
    }

    closeDialog() {
    	this.dialog = false;
    }

    send(forumMessageForm) {
        this.loading = true;
        this.forumMessageService.CreateNewForumMessage(forumMessageForm.value).subscribe(result => {
            if(result.success){
                this.openDialog();
                this.getForumMessages();
                this.formMessage();
            }
        });
    }

}