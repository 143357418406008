import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

// Providers
import { UserService } from '../../../providers';

@Component({
  	selector: 'app-account',
  	templateUrl: './account.html',
  	providers: [  ],
    animations: [
        // Apparition popup
        trigger('fadeIn', [
            transition(':enter', [
                style({
                    opacity:'0',
                }),
                animate(200, style({
                    opacity:'1',
                })) 
            ])
        ]),
        trigger('slideIn', [
            transition(':enter', [
                style({
                    transform: 'translateY(25vh)',
                    opacity: '0'
                }),
                animate(200, style({
                    transform: 'translateY(0)',
                    opacity: '1'
                })) 
            ])
        ]),
        trigger('fadeOut', [
            transition(':leave', [
                style({
                    opacity:'1',
                }),
                animate(200, style({
                    opacity:'0',
                })) 
            ])
        ])
    ]
})
export class AccountPage implements OnInit {

  	public loading = false;
    user: object;

    dialog: boolean = false;

  	constructor(
  		private formBuilder: FormBuilder,
      private userService : UserService,
      private toastr: ToastrService
  	) { 

  	}
  
  	ngOnInit() {
    	this.loading = true;

        this.user = this.formBuilder.group({
         	lastName: ['', Validators.required],
         	firstName: ['', Validators.required],
         	email: ['', Validators.required],
         	password: ['', Validators.required],
          password_confirm: ['', Validators.required],
         	profile_photo: ['', Validators.required]
        });

        this.runData();
  	}

    runData() {
      this.userService.me().subscribe(result => {
        if(result.success){
          this.setUserForm(result.data);
        }
      });
    }

    setUserForm(data) {
        this.user = this.formBuilder.group({
           lastName: [data.lastName, Validators.required],
           firstName: [data.firstName, Validators.required],
           email: [data.email, Validators.required],
           password: ['', Validators.required],
           password_confirm: ['', Validators.required],
           profile_photo: ['', Validators.required]
        });
    }

    openDialog() {
      this.dialog = true;
    }

    closeDialog() {
      this.dialog = false;
    }

    edit(user) {
        this.loading = true;

        let data = {
          lastName: user.value.lastName,
          firstName: user.value.firstName,
          email: user.value.email
        };
        let pass = false;

        if(user.value.password != "" && user.value.password != null && user.value.password != undefined){
          if(user.value.password === user.value.password_confirm){
            data['password'] = user.value.password;
            pass = true;
          } else {
            this.toastr.error('Les mots de passe ne correspondent pas.');
          }
        } else {
          pass = true;
        }

        if(pass){
          this.userService.update(data).subscribe(result => {
            if(result.success){
              this.openDialog();
              this.runData();
            }
          });
        }
    }
}
