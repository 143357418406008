import { Component, OnInit, EventEmitter } from '@angular/core';

@Component({
  	selector: 'app-compare-form',
  	templateUrl: './compare-form.html',
  	providers: [  ]
})
export class CompareForm implements OnInit {

  	public loading = false;

  	// Dépliage comparaison
  	compareField : boolean = false;
  	yearField : boolean = false;

  	// Valeurs comparaison
  	structureText : string = null;
  	volumeText : string = null;
  	yearText : string = null;

  	// Toggle comparaison
    comparison : boolean = false;
  	comparisonCharts: any = new EventEmitter();

    // Inputs
    structure = null;
    volume = null;
    year = null;
 

  	constructor() { 
  
  	}
  
  	ngOnInit() {
    	this.loading = true;
  	}

    emitComparisonEvent() {
        this.comparisonCharts.emit({comparison: this.comparison, structure: this.structureText, volume: this.volumeText, year: this.yearText});
    }

    getComparisonEmitter() {
        return this.comparisonCharts;
    }

    /* Affichage des éléments de comparaison après action sur le formulaire */
    displayComparison() {
      //this.comparison = true;     // Affichage comparaison
      this.compareField = false;  // Fermeture du panneau
      this.yearField = false;     // Fermeture du panneau
      this.emitComparisonEvent();
    }

  	selectStructure(value) {
  		this.structureText = value['target']['labels'][0]['innerText']; // Affichage de la sélection dans le champ
      this.displayComparison();
  	}

  	selectVolume(value) {
  		this.volumeText = value['target']['labels'][0]['innerText']; // Affichage de la sélection dans le champ
      this.displayComparison();
  	}

  	selectYear(value) {
  		this.yearText = value['target']['labels'][0]['innerText']; // Affichage de la sélection dans le champ
      this.displayComparison();
  	}

    resetFilters() {
      this.structure = null;
      this.volume = null;
      this.year = null;
      this.structureText = null;
      this.volumeText = null;
      this.yearText = null;
      this.comparison = false;
      this.emitComparisonEvent();
    }
}
