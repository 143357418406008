import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

// Providers
import { 
	AdminService,
	UserService
} from '../../providers';

@Component({
  	selector: 'app-admin',
  	templateUrl: './dashboard.html',
  	providers: [  ],
    animations: [
        // Apparition popup
        trigger('fadeIn', [
            transition(':enter', [
                style({
                    opacity:'0',
                }),
                animate(200, style({
                    opacity:'1',
                })) 
            ])
        ]),
        trigger('slideIn', [
            transition(':enter', [
                style({
                    transform: 'translateY(25vh)',
                    opacity: '0'
                }),
                animate(200, style({
                    transform: 'translateY(0)',
                    opacity: '1'
                })) 
            ])
        ]),
        trigger('fadeOut', [
            transition(':leave', [
                style({
                    opacity:'1',
                }),
                animate(200, style({
                    opacity:'0',
                })) 
            ])
        ]),
        // Déploiement tableaux
        trigger('expandTable', [
            transition(':enter', [
                style({
                	height:'65px',
                	overflow:'hidden'
                })
            ]),
            state('false', style({
                height: '65px',
                overflow:'hidden'
            })),
            state('true', style({
                height: '*',
                overflow:'hidden'
            })),
            transition('* => true', [
                style({height:'65px'}),
                animate(200, style({height:'*', overflow:'hidden'}))
            ]),
            transition('* => false', [
                style({height:'*'}),
                animate(200, style({height:'65px', overflow:'hidden'}))
            ])
        ])
    ]
})
export class AdminPage implements OnInit {

  	public loading = false;

  	idForUpdate: string;

  	// Admin forum
  	forumCategories = [];
  	formCategory: any;
  	dialogCategory: boolean = false;
  	forumCategoriesExpand : boolean = false;

  	// Admin center
  	centers = [];
  	formCenter: any;
  	dialogCenter: boolean = false;
  	centersExpand : boolean = false;

  	// Admin user
  	users = [];
  	formUser: any;
  	dialogUser: boolean = false;
  	usersExpand : boolean = false;

  	// Admin contact
  	contacts = [];
  	formContact: any;
  	dialogContact: boolean = false;
  	contactsExpand : boolean = false;

  	constructor(
      private adminService: AdminService,
      private userService: UserService,
      private formBuilder: FormBuilder,
      private toastr: ToastrService,
      private router: Router
    ) { 
  
  	}
  
  	ngOnInit() {
  		this.initForms();
  		this.userService.me().subscribe(result => {
  			if(result.success){
  				if(result.data.role === "admin"){
  					this.getForumCategories();
  					this.getCenters();
  					this.getUsers();
  					this.getContacts();
  				} else {
  					this.router.navigate(['/404']);
  				}
  			} else {
  				this.router.navigate(['/404']);
  			}
  		}, err => {
  			this.router.navigate(['/404']);
  		});
  	}

  	initForms() {
        this.formCategory = this.formBuilder.group({
          	id: ['', Validators.required],
          	name: ['', Validators.required]
        });
        this.formCenter = this.formBuilder.group({
          	id: ['', Validators.required],
          	name: ['', Validators.required],
      			address: ['', Validators.required],
      			postalCode: ['', Validators.required],
      			city: ['', Validators.required]
        });
        this.formUser = this.formBuilder.group({
          	id: ['', Validators.required],
      			firstName: ['', Validators.required],
      			lastName: ['', Validators.required],
      			email: ['', Validators.required],
      			password: ['', Validators.required],
      			centerId: ['', Validators.required]
        });
        this.formContact = this.formBuilder.group({
          	id: ['', Validators.required],
      			object: ['', Validators.required],
      			message: ['', Validators.required]
        });  		
  	}

  	prepareForm(id, data) {
  		if(data != null && data != undefined && data != ""){
  			if(id === "category"){
				this.dialogCategory = true;
				this.dialogCenter = false;
				this.dialogUser = false;
				this.dialogContact = false;
		        this.formCategory = this.formBuilder.group({
		          	id: [data._id, Validators.required],
		          	name: [data.name, Validators.required]
		        });
  			}
  			if(id === "center"){
				this.dialogCategory = false;
				this.dialogCenter = true;
				this.dialogUser = false;
				this.dialogContact = false;
		        this.formCenter = this.formBuilder.group({
		          	id: [data._id, Validators.required],
		          	name: [data.name, Validators.required],
      					address: [data.address, Validators.required],
      					postalCode: [data.postalCode, Validators.required],
      					city: [data.city, Validators.required]
		        });
  			}
  			if(id === "user"){
				this.dialogCategory = false;
				this.dialogCenter = false;
				this.dialogUser = true;
				this.dialogContact = false;
		        this.formUser = this.formBuilder.group({
		          	id: [data._id, Validators.required],
      					firstName: [data.firstName, Validators.required],
      					lastName: [data.lastName, Validators.required],
      					email: [data.email, Validators.required],
      					password: ['', Validators.required],
      					centerId: [data.centerId, Validators.required]
		        });
  			}
  			if(id === "contact"){
				this.dialogCategory = false;
				this.dialogCenter = false;
				this.dialogUser = false;
				this.dialogContact = true;
		        this.formContact = this.formBuilder.group({
		          	id: [data._id, Validators.required],
      					object: [data.object, Validators.required],
      					message: [data.message, Validators.required]
		        });
  			}
  		} else {
  			if(id === "category"){
  				this.dialogCategory = true;
  				this.dialogCenter = false;
  				this.dialogUser = false;
  				this.dialogContact = false;
          this.formCategory = this.formBuilder.group({
              id: ['', Validators.required],
              name: ['', Validators.required]
          });
  			}
  			if(id === "center"){
  				this.dialogCategory = false;
  				this.dialogCenter = true;
  				this.dialogUser = false;
  				this.dialogContact = false;
          this.formCenter = this.formBuilder.group({
              id: ['', Validators.required],
              name: ['', Validators.required],
              address: ['', Validators.required],
              postalCode: ['', Validators.required],
              city: ['', Validators.required]
          });
  			}
  			if(id === "user"){
  				this.dialogCategory = false;
  				this.dialogCenter = false;
  				this.dialogUser = true;
  				this.dialogContact = false;
          this.formUser = this.formBuilder.group({
              id: ['', Validators.required],
              firstName: ['', Validators.required],
              lastName: ['', Validators.required],
              email: ['', Validators.required],
              password: ['', Validators.required],
              centerId: ['', Validators.required]
          });
  			}
  			if(id === "contact"){
  				this.dialogCategory = false;
  				this.dialogCenter = false;
  				this.dialogUser = false;
  				this.dialogContact = true;
          this.formContact = this.formBuilder.group({
              id: ['', Validators.required],
              object: ['', Validators.required],
              message: ['', Validators.required]
          });
  			}
  		}
  	}

  	getForumCategories() {
  		this.adminService.getForumCategories().subscribe(result => {
  			if(result.success){
  				this.forumCategories = result.data;
  			}
  		});
  	}

  	updateOrCreateForumCategorie() {
  		this.loading = true;
  		this.adminService.updateOrCreateForumCategorie(this.formCategory.value).subscribe(result => {
  			this.loading = false;
  			if(result.success){
  				this.getForumCategories();
  				this.initForms();
  				this.dialogCategory = false;
  			} else {
  				this.toastr.error("Le formulaire est incomplet.");
  			}
  		}, err => {
  			this.loading = false;
  		});
  	}

  	getCenters() {
  		this.adminService.getCenters().subscribe(result => {
  			if(result.success){
  				this.centers = result.data;
  			}
  		});
  	}

  	updateOrCreateCenter() {
  		this.loading = true;
  		this.adminService.updateOrCreateCenter(this.formCenter.value).subscribe(result => {
  			this.loading = false;
  			if(result.success){
  				this.getCenters();
  				this.initForms();
  				this.dialogCenter = false;
  			} else {
  				this.toastr.error("Le formulaire est incomplet.");
  			}
  		}, err => {
  			this.loading = false;
  		});
  	}

  	getUsers() {
  		this.adminService.getUsers().subscribe(result => {
  			if(result.success){
  				this.users = result.data;
  			}
  		});
  	}

  	updateOrCreateUser() {
  		this.loading = true;

      let data = {
        lastName: this.formUser.value.lastName,
        firstName: this.formUser.value.firstName,
        email: this.formUser.value.email,
        centerId: this.formUser.value.centerId
      };
      let pass = false;

      if(this.formUser.value.id != "" && this.formUser.value.id != null && this.formUser.value.id != undefined){
        data['id'] = this.formUser.value.id;
      }

      if(this.formUser.value.password != "" && this.formUser.value.password != null && this.formUser.value.password != undefined){
        data['password'] = this.formUser.value.password;
        pass = true;
      } else {
        pass = true;
      }

      if(pass){
        this.adminService.updateOrCreateUser(data).subscribe(result => {
          this.loading = false;
          if(result.success){
            this.getUsers();
            this.initForms();
            this.dialogUser = false;
          } else {
            this.toastr.error("Le formulaire est incomplet.");
          }
        }, err => {
          this.loading = false;
        });
      }
  	}

  	getContacts() {
  		this.adminService.getContacts().subscribe(result => {
  			if(result.success){
  				this.contacts = result.data;
  			}
  		});
  	}

  	updateContact() {
  		this.loading = true;
  		this.adminService.updateContact(this.formContact.value).subscribe(result => {
  			this.loading = false;
  			if(result.success){
  				this.getContacts();
  				this.initForms();
  				this.dialogContact = false;
  			} else {
  				this.toastr.error("Le formulaire est incomplet.");
  			}
  		}, err => {
			this.loading = false;
  		});
  	}

  	closeDialog() {
		this.dialogCategory = false;
		this.dialogCenter = false;
		this.dialogUser = false;
		this.dialogContact = false;
  	}
}