import { Component, OnInit } from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';

import { NgModule, LOCALE_ID } from '@angular/core';

import { registerLocaleData } from '@angular/common';

import localeFr from '@angular/common/locales/fr';


registerLocaleData(localeFr);

// Providers
import { 
    ForumCategoryService,
    ForumThreadService
 } from '../../../providers';

@Component({
  	selector: 'app-forum',
    templateUrl: './forum.html',
    providers: [ {provide: LOCALE_ID, useValue: "fr-FR" } ],
    animations: [       
        // Déploiement formulaire d'ajout
        trigger('expand', [
            transition(':enter', [
                style({
                    top:'-100vh',
                    opacity: 0,
                }),
                animate(300, style({
                    top:'0',
                    opacity: 1,
                })) 
            ]),
            transition(':leave', [
                style({
                    top:'0',
                    opacity: 1,
                }),
                animate(300, style({
                    top:'-100vh',
                    opacity: 0,
                })) 
            ])
        ])
    ]
})
export class ForumPage implements OnInit {

  	public loading = false;
    forum: any;
    forumLists: any;
    thread: any;
    lastMessage: any;
    forumThread: any;

    //Category field on create forum thread form
    categories = [];
    selectedCategory: string;

    dialog: boolean = false;

    formCategoryError: boolean = false;
    formTitleError: boolean = false;
    formMessageError: boolean = false;

    startPage : Number;
    paginationLimit:Number; 

    //Search filter fields
    public search: any;
    public cat: any;
    selectedCategoryFilter: string;

    filterByKeyword: any;
    filterByCategory: any;
    filterByFavorite: any;

    itemFavorite: any;

    itemCategoryPager: any;

    categoryItem;

    // Toggle elements
    addForm: boolean = false;



  	constructor(
        private forumCategoryService: ForumCategoryService,
        private forumThreadService: ForumThreadService,
  		private formBuilder: FormBuilder,
  	) { 
        this.startPage = 0;
        this.paginationLimit = 3;
      }
      
  
  	ngOnInit() {
        this.loading = true;
        
        this.forumThread = this.formBuilder.group({
            subject: ['', Validators.required],
            category: ['', Validators.required],
            message: ['', Validators.required]
        });

        this.filterByKeyword = this.formBuilder.group({
            keyword: ['', Validators.required]
        });

        this.filterByCategory = this.formBuilder.group({
            category: ['', Validators.required]
        });

        this.filterByFavorite = this.formBuilder.group({
            favorites: ['', Validators.required]
        });



        this.forumCategoryService.getAllForumCategoryName().subscribe(result => {
            if (result.success) {
                this.categories = result.data;
            }
        });


        this.getForumList();
    }

    

    showMoreItems(itemid) {
        if (itemid) {
            this.categoryItem = itemid;
            this.paginationLimit = Number(this.paginationLimit);
        }
        this.itemCategoryPager = itemid;
        let pager = {
            pagerId: this.paginationLimit,
            categoryPager: this.itemCategoryPager,
            keyword: this.filterByKeyword.value.keyword,
            category: this.filterByCategory.value.category,
            filterFavorite: this.filterByFavorite.value.favorites
        }

        this.forumCategoryService.getAllForumCategory(pager).subscribe(result => {
            if (result.success) {
                this.forumLists = result.data;
            }
        });        
    }

    showLessItems(itemid) {
        if (itemid) {
            this.categoryItem = '';
            this.paginationLimit = Number(this.paginationLimit);
        }
        this.itemCategoryPager = '';
        let pager = {
            pagerId: this.paginationLimit,
            categoryPager: this.itemCategoryPager,
            keyword: this.filterByKeyword.value.keyword,
            category: this.filterByCategory.value.category,
            filterFavorite: this.filterByFavorite.value.favorites,
            pagerLess: 'moins'
        }

        this.forumCategoryService.getAllForumCategory(pager).subscribe(result => {
            if (result.success) {
                this.forumLists = result.data;
            }
        });        
    }
 
      
    getForumList() {

        var categoryItemGet = this.categoryItem;

        let filter = {};

        if (categoryItemGet == undefined) {
            filter = {
                keyword: this.filterByKeyword.value.keyword,
                category: this.filterByCategory.value.category,
                filterFavorite: this.filterByFavorite.value.favorites
            }
        }
        else {
            filter = {
                keyword: this.filterByKeyword.value.keyword,
                category: this.filterByCategory.value.category,
                categoryPager: categoryItemGet,
                filterFavorite: this.filterByFavorite.value.favorites
            }  
        }

        this.forumCategoryService.getAllForumCategory(filter).subscribe(result => {
            if (result.success) {
                this.forumLists = result.data;
            }
        });
    }

    addFavorite(item) {
        this.itemFavorite = item+'-add';
        this.postFavoriteToggle();
        //this.getForumList();
    }

    removeFavorite(item) {
        this.itemFavorite = item+'-remove';
        this.postFavoriteToggle();
        //this.getForumList();
    }
    
    scroll(el: HTMLElement) {
        el.scrollIntoView({behavior: "smooth", block: "start", inline: "center"});
    }


    postFavoriteToggle() {


        let favorite = {
            favoriteId: this.itemFavorite
        }

        this.forumCategoryService.getAllForumCategory(favorite).subscribe(result => {
            if(result){
                this.getForumList(); 
            }
        });
    }

    openDialog() {
    	this.dialog = true;
    }

    closeDialog() {
    	this.dialog = false;
    }


    send(forumThread) {
        this.loading = true;
        this.formCategoryError = false;
        this.formTitleError = false;
        this.formMessageError = false;

        this.forumThreadService.CreateNewForumThread(forumThread.value).subscribe(result => {
            if(result.success){
                this.openDialog();
                this.getForumList();
                this.forumThread.reset();
            }
            else {
                console.log(forumThread.controls);
                if(forumThread.controls.category.value == undefined) {
                    this.formCategoryError = true;
                }
                if(forumThread.controls.subject.value == "") {
                    this.formTitleError = true;
                }
                if(forumThread.controls.message.value == "") {
                    this.formMessageError = true;
                }
            }
        });
    }
}