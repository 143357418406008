import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

// Providers
import { 
    MyCenterService,
    UserService
} from '../../../providers';

// Compteur champs
const countControls = (control: AbstractControl): number => {
  if (control instanceof FormControl) {
    return 1;
  }

  if (control instanceof FormArray) {
    return control.controls.reduce((acc, curr) => acc + countControls(curr), 0);
  }

  if (control instanceof FormGroup) {
    return Object.keys(control.controls)
      .map(key => control.controls[key])
      .reduce((acc, curr) => acc + countControls(curr), 0);
  }
}

@Component({
  	selector: 'app-my-center',
  	templateUrl: './my-center.html',
  	providers: [  ],
    animations: [
        // Apparition popup
        trigger('fadeIn', [
            transition(':enter', [
                style({
                    opacity:'0',
                }),
                animate(200, style({
                    opacity:'1',
                })) 
            ])
        ]),
        trigger('slideIn', [
            transition(':enter', [
                style({
                    transform: 'translateY(25vh)',
                    opacity: '0'
                }),
                animate(200, style({
                    transform: 'translateY(0)',
                    opacity: '1'
                })) 
            ])
        ]),
        trigger('fadeOut', [
            transition(':leave', [
                style({
                    opacity:'1',
                }),
                animate(200, style({
                    opacity:'0',
                })) 
            ])
        ]),
        // Déploiement categories
        trigger('expandCategory', [
            transition(':enter', [
                style({
                    height:'90px',
                    overflow:'hidden'
                })
            ]),
            state('false', style({
                height: '90px',
                overflow:'hidden'
            })),
            state('true', style({
                height: '*',
                overflow:'hidden'
            })),
            transition('* => true', [
                style({height:'90px'}),
                animate(200, style({height:'*', overflow:'hidden'}))
            ]),
            transition('* => false', [
                style({height:'*'}),
                animate(200, style({height:'90px', overflow:'hidden'}))
            ])
        ]),
    ]
})
export class MyCenterPage implements OnInit {

    public loading = false;
    center: any;
    centerName: string;

    dialog: boolean = false;

    // Accordeons
    generalityExpand: boolean = false;
    tonnageExpand: boolean = false;
    productionExpand: boolean = false;
    productionMaterialExpand: boolean = false;
    loadsExpand: boolean = false;
    keysExpand: boolean = false;

    yearSelected: any;
    years = [];

    // Status form
    general: number = 0;
    tonnageRecipes: number = 0;
    numbers: number = 0;
    productionEquipment: number = 0;
    loads: number = 0;
    distributionKey: number = 0;

    // Items counter
    generalItems = 0;
    tonnageItems = 0;
    productionItems = 0;
    productionMaterialItems = 0;
    loadsItems = 0;
    keysItems = 0;

    draftPossible: boolean = true;
    draftMode: boolean = false;
    draftCenterId: string;


  	constructor(
      private myCenterService: MyCenterService,
      private userService: UserService,
      private formBuilder: FormBuilder,
      private toastr: ToastrService
  	) { 

  	}
  
  	ngOnInit() {
        this.initForm();

        let today = new Date();
        let year = today.getFullYear();
        this.yearSelected = year;

        for (let y = 0; y < 3; ++y) {
            this.years.push(year-y);
        }

        this.getYear();

        this.myCenterService.myCenterName().subscribe(result => {
            if(result.success){
                this.centerName = result.data.name;
            }
        });
  	}

    getYear() {
        this.myCenterService.myCenter(this.yearSelected).subscribe(result => {
            if(result.success){
                this.yearSelected = result.data.general.year.toString();
                this.setForm(result.data);
                this.draftPossible = false;
                this.draftMode = false;
            } else {
                this.draftMode = true;
                this.draftPossible = true;
                this.getDraft();
            }
        });
    }

    getDraft() {
        this.myCenterService.myDraftCenter(this.yearSelected).subscribe(result => {
            if(result.success){
                this.yearSelected = result.data.general.year.toString();
                this.setForm(result.data);
                this.draftPossible = true;
            } else {
                this.draftCenterId = undefined;
                this.draftPossible = true;
                this.initForm();
            }
        });
    }

    initForm() {
        this.center = this.formBuilder.group({
            id: ['', Validators.required],
            general: this.formBuilder.group({
                year: [this.yearSelected, Validators.required],
                structure: ['', Validators.required],
                volume: ['', Validators.required],
                address: ['', Validators.required],
                postalCode: ['', Validators.required],
                city: ['', Validators.required],
                amplitude: ['', {validators: Validators.pattern("^[0-9.]*$")}],
                openingDays: ['', Validators.required],
                openingHours: ['', Validators.required],
                workingDays: ['', Validators.required],
                workingHours: ['', Validators.required]
            }),
            tonnageRecipes: this.formBuilder.group({
                greatDish: this.formBuilder.group({
                    sheets: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    sheets240: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    underSheets: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    babysSheets: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    })
                }),
                smallDish: this.formBuilder.group({
                    tablecloth: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    tables: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    pillowcases: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    bolsterCases: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    dishtowels: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    })
                }),
                form: this.formBuilder.group({
                    davoutfits: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    foldedoutfits: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    sickshirts: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    })
                }),
                spm: this.formBuilder.group({
                    absorbantundersheets: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    kidblankets: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    twoinoneblankets: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    adultblankets: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    adultfittedsheets: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    terrytowels: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    bathtowels: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    adultbibs: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    kidbibs: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    childfittedsheets: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    craddlefittedsheets: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    diapers: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    sleepsacks: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    })
                }),
                dried: this.formBuilder.group({
                    washcloth: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    washingbands: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    laundrybags: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    }),
                    nurserytowels: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    })
                }),
                driedFolded: this.formBuilder.group({
                    residentLinen: this.formBuilder.group({
                        quantity: ['', Validators.required],
                        averageWeight: ['', Validators.required]
                    })
                })
            }),
            numbers: this.formBuilder.group({
                hanging: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required],
                    positionsByTeam: ['', Validators.required]
                }),
                sorting: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required],
                    positionsByTeam: ['', Validators.required]
                }),
                washing: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required],
                    positionsByTeam: ['', Validators.required]
                }),
                gpfinishing: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required],
                    positionsByTeam: ['', Validators.required]
                }),
                ppfinishing: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required],
                    positionsByTeam: ['', Validators.required]
                }),
                ffinishing: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required],
                    positionsByTeam: ['', Validators.required]
                }),
                marking: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required],
                    positionsByTeam: ['', Validators.required]
                }),
                spmfinishing: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required],
                    positionsByTeam: ['', Validators.required]
                }),
                sfinishing: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required],
                    positionsByTeam: ['', Validators.required]
                }),
                rfinishing: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required],
                    positionsByTeam: ['', Validators.required]
                }),
                delivery: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required],
                    positionsByTeam: ['', Validators.required]
                }),
                transportation: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required],
                    positionsByTeam: ['', Validators.required]
                }),
                framing: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required]
                }),
                maintenance: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required]
                }),
                administrative: this.formBuilder.group({
                    numberOfPositions: ['', Validators.required],
                    hourlyProductivity: ['', Validators.required]
                }),
                averagesalary: ['', Validators.required],
                payroll: ['', Validators.required],
                absenteeism: ['', Validators.required]
            }),
            productionEquipment: this.formBuilder.group({
                equipment: this.formBuilder.group({
                    tunnel: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    }),
                    sortingstation: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    }),
                    washingstation: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    }),
                    storagewashing: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    }),
                    gptunnel: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    }),
                    othertunnel: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    }),
                    driers: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    }),
                    foldinglaundry: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    }),
                    gptrain: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    }),
                    pptrain: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    }),
                    finishingtunnel: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    }),
                    bagging: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    }),
                    parts: this.formBuilder.group({
                        capacity: ['', Validators.required],
                        currentAvailability: ['', Validators.required]
                    })
                }),
                depreciation: this.formBuilder.group({
                    beforeSorting: ['', Validators.required],
                    washingRolls: ['', Validators.required],
                    sorting: ['', Validators.required],
                    tunnelsMangling: ['', Validators.required],
                    residentsLaundry: ['', Validators.required],
                    productsDepartment: ['', Validators.required],
                    cleanManut: ['', Validators.required],
                    gp: ['', Validators.required],
                    pp: ['', Validators.required],
                    shape: ['', Validators.required],
                    conveying: ['', Validators.required],
                    folding: ['', Validators.required],
                    filming: ['', Validators.required],
                    bagger: ['', Validators.required],
                    conveyor: ['', Validators.required],
                    gpao: ['', Validators.required],
                    rollsBuying: ['', Validators.required],
                    GTI: ['', Validators.required],
                    miscellaneous: ['', Validators.required],
                }),
                endWashing: this.formBuilder.group({
                    shuttles: ['', Validators.required],
                    davtunnel: ['', Validators.required],
                    folder: ['', Validators.required],
                    bagger: ['', Validators.required],
                    clalandre: ['', Validators.required],
                })
            }),
            loads: this.formBuilder.group({
                rent: [true, Validators.required],
                rentAmount: ['', Validators.required],
                financialCharges: ['', Validators.required],
                consumption: this.formBuilder.group({
                    water: this.formBuilder.group({
                        consumption: ['', Validators.required],
                        unitCost: ['', Validators.required]
                    }),
                    energy: this.formBuilder.group({
                        consumption: ['', Validators.required],
                        unitCost: ['', Validators.required]
                    }),
                    gas: this.formBuilder.group({
                        consumption: ['', Validators.required],
                        unitCost: ['', Validators.required]
                    }),
                    laundryProducts: this.formBuilder.group({
                        consumption: ['', Validators.required],
                        unitCost: ['', Validators.required]
                    }),
                    greatDish: this.formBuilder.group({
                        waterConsumption: ['', Validators.required],
                        steamConsumption: ['', Validators.required],
                        gasConsumption: ['', Validators.required],
                        laundryProductsConsumption: ['', Validators.required]
                    }),
                    smallDish: this.formBuilder.group({
                        waterConsumption: ['', Validators.required],
                        steamConsumption: ['', Validators.required],
                        gasConsumption: ['', Validators.required],
                        laundryProductsConsumption: ['', Validators.required]
                    }),
                    form: this.formBuilder.group({
                        waterConsumption: ['', Validators.required],
                        steamConsumption: ['', Validators.required],
                        gasConsumption: ['', Validators.required],
                        laundryProductsConsumption: ['', Validators.required]
                    }),
                    driedFoldedMachine: this.formBuilder.group({
                        waterConsumption: ['', Validators.required],
                        steamConsumption: ['', Validators.required],
                        gasConsumption: ['', Validators.required],
                        laundryProductsConsumption: ['', Validators.required]
                    }),
                    dried: this.formBuilder.group({
                        waterConsumption: ['', Validators.required],
                        steamConsumption: ['', Validators.required],
                        gasConsumption: ['', Validators.required],
                        laundryProductsConsumption: ['', Validators.required]
                    }),
                    residents: this.formBuilder.group({
                        waterConsumption: ['', Validators.required],
                        steamConsumption: ['', Validators.required],
                        gasConsumption: ['', Validators.required],
                        laundryProductsConsumption: ['', Validators.required]
                    })
                }),
                otherLoads: this.formBuilder.group({
                    laundryPurchase: ['', Validators.required],
                    electricity: ['', Validators.required],
                    vehicleRent: ['', Validators.required],
                    factorySupplies: ['', Validators.required],
                    fuel: ['', Validators.required],
                    administrativeSupplies: ['', Validators.required],
                    spareParts: ['', Validators.required],
                    smallEquipment: ['', Validators.required],
                    guarantees: ['', Validators.required],
                    buildingRentals: ['', Validators.required],
                    rentals: ['', Validators.required],
                    externalServices: ['', Validators.required],
                    buildingMaintenance: ['', Validators.required],
                    otherPayments: ['', Validators.required],
                    cleaning: ['', Validators.required]
                })
            }),
            distributionKey: this.formBuilder.group({
                consumptions: this.formBuilder.group({
                    hangingReception: ['', Validators.required],
                    triWash: ['', Validators.required],
                    finish: ['', Validators.required],
                    distribution: ['', Validators.required],
                    transportNetwork: ['', Validators.required]
                }),
                othercharges: this.formBuilder.group({
                    hangingReception: ['', Validators.required],
                    triWash: ['', Validators.required],
                    finish: ['', Validators.required],
                    distribution: ['', Validators.required],
                    transportNetwork: ['', Validators.required]
                }),
                financialcharges: this.formBuilder.group({
                    hangingReception: ['', Validators.required],
                    triWash: ['', Validators.required],
                    finish: ['', Validators.required],
                    distribution: ['', Validators.required],
                    transportNetwork: ['', Validators.required]
                }),
                rental: this.formBuilder.group({
                    hangingReception: ['', Validators.required],
                    triWash: ['', Validators.required],
                    finish: ['', Validators.required],
                    distribution: ['', Validators.required],
                    transportNetwork: ['', Validators.required]
                }),
                personnal: this.formBuilder.group({
                    greatdish: ['', Validators.required],
                    smalldish: ['', Validators.required],
                    shape: ['', Validators.required],
                    spm: ['', Validators.required],
                    s: ['', Validators.required],
                    resident: ['', Validators.required]
                })
            })
        });

        this.firstLoadForm();
        this.formOnChanges();

        this.generalItems = countControls(this.center['controls']['general']);
        this.tonnageItems = countControls(this.center['controls']['tonnageRecipes']);
        this.productionItems = countControls(this.center['controls']['numbers']);
        this.productionMaterialItems = countControls(this.center['controls']['productionEquipment']);
        this.loadsItems = countControls(this.center['controls']['loads']);
        this.keysItems = countControls(this.center['controls']['distributionKey']);
    }

    setForm(data) {

        this.draftCenterId = data._id;

        this.center = this.formBuilder.group({
            id: [data._id, Validators.required],
            general: this.formBuilder.group({
                year: [data.general.year, Validators.required],
                structure: [data.general.structure, Validators.required],
                volume: [data.general.volume, Validators.required],
                address: [data.general.address, Validators.required],
                postalCode: [data.general.postalCode, Validators.required],
                city: [data.general.city, Validators.required],
                amplitude: [data.general.amplitude, Validators.required],
                openingDays: [data.general.openingDays, Validators.required],
                openingHours: [data.general.openingHours, Validators.required],
                workingDays: [data.general.workingDays, Validators.required],
                workingHours: [data.general.workingHours, Validators.required]
            }),
            tonnageRecipes: this.formBuilder.group({
                greatDish: this.formBuilder.group({
                    sheets: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.greatDish.sheets.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.greatDish.sheets.averageWeight, Validators.required]
                    }),
                    sheets240: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.greatDish.sheets240.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.greatDish.sheets240.averageWeight, Validators.required]
                    }),
                    underSheets: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.greatDish.underSheets.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.greatDish.underSheets.averageWeight, Validators.required]
                    }),
                    babysSheets: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.greatDish.babysSheets.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.greatDish.babysSheets.averageWeight, Validators.required]
                    })
                }),
                smallDish: this.formBuilder.group({
                    tablecloth: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.smallDish.tablecloth.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.smallDish.tablecloth.averageWeight, Validators.required]
                    }),
                    tables: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.smallDish.tables.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.smallDish.tables.averageWeight, Validators.required]
                    }),
                    pillowcases: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.smallDish.pillowcases.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.smallDish.pillowcases.averageWeight, Validators.required]
                    }),
                    bolsterCases: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.smallDish.bolsterCases.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.smallDish.bolsterCases.averageWeight, Validators.required]
                    }),
                    dishtowels: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.smallDish.dishtowels.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.smallDish.dishtowels.averageWeight, Validators.required]
                    })
                }),
                form: this.formBuilder.group({
                    davoutfits: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.form.davoutfits.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.form.davoutfits.averageWeight, Validators.required]
                    }),
                    foldedoutfits: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.form.foldedoutfits.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.form.foldedoutfits.averageWeight, Validators.required]
                    }),
                    sickshirts: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.form.sickshirts.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.form.sickshirts.averageWeight, Validators.required]
                    })
                }),
                spm: this.formBuilder.group({
                    absorbantundersheets: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.absorbantundersheets.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.absorbantundersheets.averageWeight, Validators.required]
                    }),
                    kidblankets: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.kidblankets.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.kidblankets.averageWeight, Validators.required]
                    }),
                    twoinoneblankets: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.twoinoneblankets.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.twoinoneblankets.averageWeight, Validators.required]
                    }),
                    adultblankets: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.adultblankets.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.adultblankets.averageWeight, Validators.required]
                    }),
                    adultfittedsheets: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.adultfittedsheets.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.adultfittedsheets.averageWeight, Validators.required]
                    }),
                    terrytowels: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.terrytowels.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.terrytowels.averageWeight, Validators.required]
                    }),
                    bathtowels: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.bathtowels.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.bathtowels.averageWeight, Validators.required]
                    }),
                    adultbibs: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.adultbibs.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.adultbibs.averageWeight, Validators.required]
                    }),
                    kidbibs: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.kidbibs.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.kidbibs.averageWeight, Validators.required]
                    }),
                    childfittedsheets: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.childfittedsheets.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.childfittedsheets.averageWeight, Validators.required]
                    }),
                    craddlefittedsheets: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.craddlefittedsheets.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.craddlefittedsheets.averageWeight, Validators.required]
                    }),
                    diapers: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.diapers.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.diapers.averageWeight, Validators.required]
                    }),
                    sleepsacks: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.spm.sleepsacks.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.spm.sleepsacks.averageWeight, Validators.required]
                    })
                }),
                dried: this.formBuilder.group({
                    washcloth: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.dried.washcloth.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.dried.washcloth.averageWeight, Validators.required]
                    }),
                    washingbands: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.dried.washingbands.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.dried.washingbands.averageWeight, Validators.required]
                    }),
                    laundrybags: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.dried.laundrybags.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.dried.laundrybags.averageWeight, Validators.required]
                    }),
                    nurserytowels: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.dried.nurserytowels.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.dried.nurserytowels.averageWeight, Validators.required]
                    })
                }),
                driedFolded: this.formBuilder.group({
                    residentLinen: this.formBuilder.group({
                        quantity: [data.tonnageRecipes.driedFolded.residentLinen.quantity, Validators.required],
                        averageWeight: [data.tonnageRecipes.driedFolded.residentLinen.averageWeight, Validators.required]
                    })
                })
            }),
            numbers: this.formBuilder.group({
                hanging: this.formBuilder.group({
                    numberOfPositions: [data.numbers.hanging.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.hanging.hourlyProductivity, Validators.required],
                    positionsByTeam: [data.numbers.hanging.positionsByTeam, Validators.required]
                }),
                sorting: this.formBuilder.group({
                    numberOfPositions: [data.numbers.sorting.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.sorting.hourlyProductivity, Validators.required],
                    positionsByTeam: [data.numbers.sorting.positionsByTeam, Validators.required]
                }),
                washing: this.formBuilder.group({
                    numberOfPositions: [data.numbers.washing.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.washing.hourlyProductivity, Validators.required],
                    positionsByTeam: [data.numbers.washing.positionsByTeam, Validators.required]
                }),
                gpfinishing: this.formBuilder.group({
                    numberOfPositions: [data.numbers.gpfinishing.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.gpfinishing.hourlyProductivity, Validators.required],
                    positionsByTeam: [data.numbers.gpfinishing.positionsByTeam, Validators.required]
                }),
                ppfinishing: this.formBuilder.group({
                    numberOfPositions: [data.numbers.ppfinishing.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.ppfinishing.hourlyProductivity, Validators.required],
                    positionsByTeam: [data.numbers.ppfinishing.positionsByTeam, Validators.required]
                }),
                ffinishing: this.formBuilder.group({
                    numberOfPositions: [data.numbers.ffinishing.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.ffinishing.hourlyProductivity, Validators.required],
                    positionsByTeam: [data.numbers.ffinishing.positionsByTeam, Validators.required]
                }),
                marking: this.formBuilder.group({
                    numberOfPositions: [data.numbers.marking.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.marking.hourlyProductivity, Validators.required],
                    positionsByTeam: [data.numbers.marking.positionsByTeam, Validators.required]
                }),
                spmfinishing: this.formBuilder.group({
                    numberOfPositions: [data.numbers.spmfinishing.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.spmfinishing.hourlyProductivity, Validators.required],
                    positionsByTeam: [data.numbers.spmfinishing.positionsByTeam, Validators.required]
                }),
                sfinishing: this.formBuilder.group({
                    numberOfPositions: [data.numbers.sfinishing.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.sfinishing.hourlyProductivity, Validators.required],
                    positionsByTeam: [data.numbers.sfinishing.positionsByTeam, Validators.required]
                }),
                rfinishing: this.formBuilder.group({
                    numberOfPositions: [data.numbers.rfinishing.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.rfinishing.hourlyProductivity, Validators.required],
                    positionsByTeam: [data.numbers.rfinishing.positionsByTeam, Validators.required]
                }),
                delivery: this.formBuilder.group({
                    numberOfPositions: [data.numbers.delivery.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.delivery.hourlyProductivity, Validators.required],
                    positionsByTeam: [data.numbers.delivery.positionsByTeam, Validators.required]
                }),
                transportation: this.formBuilder.group({
                    numberOfPositions: [data.numbers.transportation.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.transportation.hourlyProductivity, Validators.required],
                    positionsByTeam: [data.numbers.transportation.positionsByTeam, Validators.required]
                }),
                framing: this.formBuilder.group({
                    numberOfPositions: [data.numbers.framing.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.framing.hourlyProductivity, Validators.required]
                }),
                maintenance: this.formBuilder.group({
                    numberOfPositions: [data.numbers.maintenance.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.maintenance.hourlyProductivity, Validators.required]
                }),
                administrative: this.formBuilder.group({
                    numberOfPositions: [data.numbers.administrative.numberOfPositions, Validators.required],
                    hourlyProductivity: [data.numbers.administrative.hourlyProductivity, Validators.required]
                }),
                averagesalary: [data.numbers.averagesalary, Validators.required],
                payroll: [data.numbers.payroll, Validators.required],
                absenteeism: [data.numbers.absenteeism, Validators.required]
            }),
            productionEquipment: this.formBuilder.group({
                equipment: this.formBuilder.group({
                    tunnel: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.tunnel.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.tunnel.currentAvailability, Validators.required]
                    }),
                    sortingstation: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.sortingstation.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.sortingstation.currentAvailability, Validators.required]
                    }),
                    washingstation: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.washingstation.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.washingstation.currentAvailability, Validators.required]
                    }),
                    storagewashing: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.storagewashing.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.storagewashing.currentAvailability, Validators.required]
                    }),
                    gptunnel: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.gptunnel.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.gptunnel.currentAvailability, Validators.required]
                    }),
                    othertunnel: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.othertunnel.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.othertunnel.currentAvailability, Validators.required]
                    }),
                    driers: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.driers.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.driers.currentAvailability, Validators.required]
                    }),
                    foldinglaundry: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.foldinglaundry.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.foldinglaundry.currentAvailability, Validators.required]
                    }),
                    gptrain: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.gptrain.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.gptrain.currentAvailability, Validators.required]
                    }),
                    pptrain: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.pptrain.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.pptrain.currentAvailability, Validators.required]
                    }),
                    finishingtunnel: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.finishingtunnel.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.finishingtunnel.currentAvailability, Validators.required]
                    }),
                    bagging: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.bagging.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.bagging.currentAvailability, Validators.required]
                    }),
                    parts: this.formBuilder.group({
                        capacity: [data.productionEquipment.equipment.parts.capacity, Validators.required],
                        currentAvailability: [data.productionEquipment.equipment.parts.currentAvailability, Validators.required]
                    })
                }),
                depreciation: this.formBuilder.group({
                    beforeSorting: [data.productionEquipment.depreciation.beforeSorting, Validators.required],
                    washingRolls: [data.productionEquipment.depreciation.washingRolls, Validators.required],
                    sorting: [data.productionEquipment.depreciation.sorting, Validators.required],
                    tunnelsMangling: [data.productionEquipment.depreciation.tunnelsMangling, Validators.required],
                    residentsLaundry: [data.productionEquipment.depreciation.residentsLaundry, Validators.required],
                    productsDepartment: [data.productionEquipment.depreciation.productsDepartment, Validators.required],
                    cleanManut: [data.productionEquipment.depreciation.cleanManut, Validators.required],
                    gp: [data.productionEquipment.depreciation.gp, Validators.required],
                    pp: [data.productionEquipment.depreciation.pp, Validators.required],
                    shape: [data.productionEquipment.depreciation.shape, Validators.required],
                    conveying: [data.productionEquipment.depreciation.conveying, Validators.required],
                    folding: [data.productionEquipment.depreciation.folding, Validators.required],
                    filming: [data.productionEquipment.depreciation.filming, Validators.required],
                    bagger: [data.productionEquipment.depreciation.bagger, Validators.required],
                    conveyor: [data.productionEquipment.depreciation.conveyor, Validators.required],
                    gpao: [data.productionEquipment.depreciation.gpao, Validators.required],
                    rollsBuying: [data.productionEquipment.depreciation.rollsBuying, Validators.required],
                    GTI: [data.productionEquipment.depreciation.GTI, Validators.required],
                    miscellaneous: [data.productionEquipment.depreciation.miscellaneous, Validators.required],
                }),
                endWashing: this.formBuilder.group({
                    shuttles: [data.productionEquipment.endWashing.shuttles, Validators.required],
                    davtunnel: [data.productionEquipment.endWashing.davtunnel, Validators.required],
                    folder: [data.productionEquipment.endWashing.folder, Validators.required],
                    bagger: [data.productionEquipment.endWashing.bagger, Validators.required],
                    clalandre: [data.productionEquipment.endWashing.clalandre, Validators.required],
                })
            }),
            loads: this.formBuilder.group({
                rent: [data.loads.rent, Validators.required],
                rentAmount: [data.loads.rentAmount, Validators.required],
                financialCharges: [data.loads.financialCharges, Validators.required],
                consumption: this.formBuilder.group({
                    water: this.formBuilder.group({
                        consumption: [data.loads.consumption.water.consumption, Validators.required],
                        unitCost: [data.loads.consumption.water.unitCost, Validators.required]
                    }),
                    energy: this.formBuilder.group({
                        consumption: [data.loads.consumption.energy.consumption, Validators.required],
                        unitCost: [data.loads.consumption.energy.unitCost, Validators.required]
                    }),
                    gas: this.formBuilder.group({
                        consumption: [data.loads.consumption.gas.consumption, Validators.required],
                        unitCost: [data.loads.consumption.gas.unitCost, Validators.required]
                    }),
                    laundryProducts: this.formBuilder.group({
                        consumption: [data.loads.consumption.laundryProducts.consumption, Validators.required],
                        unitCost: [data.loads.consumption.laundryProducts.unitCost, Validators.required]
                    }),
                    greatDish: this.formBuilder.group({
                        waterConsumption: [data.loads.consumption.greatDish.waterConsumption, Validators.required],
                        steamConsumption: [data.loads.consumption.greatDish.steamConsumption, Validators.required],
                        gasConsumption: [data.loads.consumption.greatDish.gasConsumption, Validators.required],
                        laundryProductsConsumption: [data.loads.consumption.greatDish.laundryProductsConsumption, Validators.required]
                    }),
                    smallDish: this.formBuilder.group({
                        waterConsumption: [data.loads.consumption.smallDish.waterConsumption, Validators.required],
                        steamConsumption: [data.loads.consumption.smallDish.steamConsumption, Validators.required],
                        gasConsumption: [data.loads.consumption.smallDish.gasConsumption, Validators.required],
                        laundryProductsConsumption: [data.loads.consumption.smallDish.laundryProductsConsumption, Validators.required]
                    }),
                    form: this.formBuilder.group({
                        waterConsumption: [data.loads.consumption.form.waterConsumption, Validators.required],
                        steamConsumption: [data.loads.consumption.form.steamConsumption, Validators.required],
                        gasConsumption: [data.loads.consumption.form.gasConsumption, Validators.required],
                        laundryProductsConsumption: [data.loads.consumption.form.laundryProductsConsumption, Validators.required]
                    }),
                    driedFoldedMachine: this.formBuilder.group({
                        waterConsumption: [data.loads.consumption.driedFoldedMachine.waterConsumption, Validators.required],
                        steamConsumption: [data.loads.consumption.driedFoldedMachine.steamConsumption, Validators.required],
                        gasConsumption: [data.loads.consumption.driedFoldedMachine.gasConsumption, Validators.required],
                        laundryProductsConsumption: [data.loads.consumption.driedFoldedMachine.laundryProductsConsumption, Validators.required]
                    }),
                    dried: this.formBuilder.group({
                        waterConsumption: [data.loads.consumption.dried.waterConsumption, Validators.required],
                        steamConsumption: [data.loads.consumption.dried.steamConsumption, Validators.required],
                        gasConsumption: [data.loads.consumption.dried.gasConsumption, Validators.required],
                        laundryProductsConsumption: [data.loads.consumption.dried.laundryProductsConsumption, Validators.required]
                    }),
                    residents: this.formBuilder.group({
                        waterConsumption: [data.loads.consumption.residents.waterConsumption, Validators.required],
                        steamConsumption: [data.loads.consumption.residents.steamConsumption, Validators.required],
                        gasConsumption: [data.loads.consumption.residents.gasConsumption, Validators.required],
                        laundryProductsConsumption: [data.loads.consumption.residents.laundryProductsConsumption, Validators.required]
                    })
                }),
                otherLoads: this.formBuilder.group({
                    laundryPurchase: [data.loads.otherLoads.laundryPurchase, Validators.required],
                    electricity: [data.loads.otherLoads.electricity, Validators.required],
                    vehicleRent: [data.loads.otherLoads.vehicleRent, Validators.required],
                    factorySupplies: [data.loads.otherLoads.factorySupplies, Validators.required],
                    fuel: [data.loads.otherLoads.fuel, Validators.required],
                    administrativeSupplies: [data.loads.otherLoads.administrativeSupplies, Validators.required],
                    spareParts: [data.loads.otherLoads.spareParts, Validators.required],
                    smallEquipment: [data.loads.otherLoads.smallEquipment, Validators.required],
                    guarantees: [data.loads.otherLoads.guarantees, Validators.required],
                    buildingRentals: [data.loads.otherLoads.buildingRentals, Validators.required],
                    rentals: [data.loads.otherLoads.rentals, Validators.required],
                    externalServices: [data.loads.otherLoads.externalServices, Validators.required],
                    buildingMaintenance: [data.loads.otherLoads.buildingMaintenance, Validators.required],
                    otherPayments: [data.loads.otherLoads.otherPayments, Validators.required],
                    cleaning: [data.loads.otherLoads.cleaning, Validators.required]
                })
            }),
            distributionKey: this.formBuilder.group({
                consumptions: this.formBuilder.group({
                    hangingReception: [data.distributionKey.consumptions.hangingReception, Validators.required],
                    triWash: [data.distributionKey.consumptions.triWash, Validators.required],
                    finish: [data.distributionKey.consumptions.finish, Validators.required],
                    distribution: [data.distributionKey.consumptions.distribution, Validators.required],
                    transportNetwork: [data.distributionKey.consumptions.transportNetwork, Validators.required]
                }),
                othercharges: this.formBuilder.group({
                    hangingReception: [data.distributionKey.othercharges.hangingReception, Validators.required],
                    triWash: [data.distributionKey.othercharges.triWash, Validators.required],
                    finish: [data.distributionKey.othercharges.finish, Validators.required],
                    distribution: [data.distributionKey.othercharges.distribution, Validators.required],
                    transportNetwork: [data.distributionKey.othercharges.transportNetwork, Validators.required]
                }),
                financialcharges: this.formBuilder.group({
                    hangingReception: [data.distributionKey.financialcharges.hangingReception, Validators.required],
                    triWash: [data.distributionKey.financialcharges.triWash, Validators.required],
                    finish: [data.distributionKey.financialcharges.finish, Validators.required],
                    distribution: [data.distributionKey.financialcharges.distribution, Validators.required],
                    transportNetwork: [data.distributionKey.financialcharges.transportNetwork, Validators.required]
                }),
                rental: this.formBuilder.group({
                    hangingReception: [data.distributionKey.rental.hangingReception, Validators.required],
                    triWash: [data.distributionKey.rental.triWash, Validators.required],
                    finish: [data.distributionKey.rental.finish, Validators.required],
                    distribution: [data.distributionKey.rental.distribution, Validators.required],
                    transportNetwork: [data.distributionKey.rental.transportNetwork, Validators.required]
                }),
                personnal: this.formBuilder.group({
                    greatdish: [data.distributionKey.personnal.greatdish, Validators.required],
                    smalldish: [data.distributionKey.personnal.smalldish, Validators.required],
                    shape: [data.distributionKey.personnal.shape, Validators.required],
                    spm: [data.distributionKey.personnal.spm, Validators.required],
                    s: [data.distributionKey.personnal.s, Validators.required],
                    resident: [data.distributionKey.personnal.resident, Validators.required]
                })
            })
        });

        this.firstLoadForm();
        this.formOnChanges();
    }

    firstLoadForm() {
        this.general = this.checkNumberItem('general',  this.center.value.general);
        this.tonnageRecipes = this.checkNumberItem('tonnageRecipes', this.center.value.tonnageRecipes);
        this.numbers = this.checkNumberItem('numbers', this.center.value.numbers);
        this.productionEquipment = this.checkNumberItem('productionEquipment', this.center.value.productionEquipment);
        this.loads = this.checkNumberItem('loads', this.center.value.loads);
        this.distributionKey = this.checkNumberItem('distributionKey', this.center.value.distributionKey);
    }

    formOnChanges() {
        this.center.valueChanges.subscribe(val => {
            this.general = this.checkNumberItem('general', val.general);
            this.tonnageRecipes = this.checkNumberItem('tonnageRecipes', val.tonnageRecipes);
            this.numbers = this.checkNumberItem('numbers', val.numbers);
            this.productionEquipment = this.checkNumberItem('productionEquipment', val.productionEquipment);
            this.loads = this.checkNumberItem('loads', val.loads);
            this.distributionKey = this.checkNumberItem('distributionKey', val.distributionKey);
        });
    }

    checkNumberItem(section, data) {
        let items = [];
        let firstColumn = Object.values(data);
        for (let a = 0; a < firstColumn.length; a++) {
            if(typeof firstColumn[a] == 'object'){
                if(firstColumn[a] != null){
                    let secondColumn = Object.values(firstColumn[a]);
                    for (let b = 0; b < secondColumn.length; b++) {
                        if(typeof secondColumn[b] == 'object'){
                            if(secondColumn[b] != null){
                                let thirdColumn = Object.values(secondColumn[b]);
                                for (let c = 0; c < thirdColumn.length; c++) {
                                    if(typeof thirdColumn[c] == 'object'){
                                        let thirdColumn = Object.values(secondColumn[b]);
                                    } else {
                                        items.push(thirdColumn[c]);
                                    }
                                }
                            } else {
                                if(secondColumn[b] != ""){
                                    items.push(secondColumn[b]);
                                }
                            }
                        } else {
                            items.push(secondColumn[b]);
                        }
                    }
                } else {
                    if(firstColumn[a] != ""){
                        items.push(firstColumn[a]);
                    }
                }
            } else {
                items.push(firstColumn[a]);
            }
        }

        let validItem = 0;

        for (let i = 0; i < items.length; i++) {   
            if(items[i] != "" && items[i] != null && items[i] != undefined){
                validItem++;
            } else {
                if(items[i] === 0){
                    validItem++;
                }
            }
        }

        return validItem;
    }

    numberOfItemChecked(values) {
        let result = 0;
        for (let v = 0; v < values.length; v++) {
            if(values[v] != "" && values[v] != null && values[v] != undefined){
                if(!isNaN(values[v])){
                    result++;
                }
            }
        }
        return result;
    }

    changeYear(year) {
        this.yearSelected = year;
        this.getYear();
    }

    openDialog() {
      this.dialog = true;
    }

    closeDialog() {
      this.dialog = false;
    }

    edit(center) {
        this.loading = true;

        // if(this.center.status === "VALID"){

            if(this.draftMode == true){
                center.value.id = "";
            }

            this.myCenterService.createOrUpdate(center.value).subscribe(result => {
                this.loading = false;
                if(result.success){
                    this.openDialog();
                    this.draftMode = false;
                    this.draftPossible = false;
                    this.getYear();
                } else {
                    this.toastr.error("Impossible d'enregistrer les valeurs, le formulaire doit être complet, s'il ne l'est pas vous pouvez seulement enregistrer en brouillon");
                }
            }, err => {
                this.loading = false;
            });
        // } else {
        //     this.toastr.error("Impossible d'enregistrer les valeurs, le formulaire doit être complet, s'il ne l'est pas vous pouvez seulement enregistrer en brouillon");

        // }
    }

    editDraft(center) {
        this.loading = true;
        if(this.draftCenterId != undefined && this.draftCenterId != null){
            center.value.id = this.draftCenterId;
        }
        this.myCenterService.createOrUpdateDraft(center.value).subscribe(result => {
            this.loading = false;
            if(result.success){
                this.getDraft();
                this.openDialog();
            } else {
               this.toastr.error("Une erreur nous empêche de sauvegarder la progression, veuillez attendre et re-essayer plus tard");
            }
        }, err => {
            this.loading = false;
        });
    }
}