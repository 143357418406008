import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-404',
  templateUrl: './404.html'
})
export class NotFoundPage implements OnInit {

  public loading = false;

  constructor() { 
  
  }
  
  ngOnInit() {
    this.loading = true;

  }
}