import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../providers';
import { AppComponent } from '../../../app.component';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
 
@Component({
	selector: 'app-new-password',
	templateUrl: './new-password.html'
})

export class NewPasswordPage implements OnInit {

    loading = false;
    form: any;
    id: any;
    code: any;
    email: any;
 
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private toastr: ToastrService,
        private AppComponent: AppComponent,
        private formBuilder: FormBuilder) {

        }
 
    ngOnInit() {

		this.route.params.subscribe(params => {
			if(params.id != undefined && params.code != undefined){
		        this.form = this.formBuilder.group({
		            password: ['', Validators.required]
		        });
		        this.id = params.id;
		        this.code = params.code;
		        this.email = params.email;
			} else {
				this.loading = false;
				this.router.navigate(['/404']);
			}
		});
    }

    send(data) {
        if(
            data.value.password != null && data.value.password != ''
        ){
            this.loading = true;
	        let newData = {
	        	password: data.value.password
	        };
            this.authService.setNewPassword(newData, this.id, this.code).subscribe(result => {
                if(result.success){
		            this.authService.auth({email: this.email, password: data.value.password}).subscribe(result => {
		                if(result.success == true){
		                    result.token = 'Bearer '+result.token;
		                    if (result && result.token) {
		                        localStorage.setItem('currentUser', JSON.stringify(result));
		                        this.AppComponent.checkConnection();
		                        if(result.role === "admin"){
		                          this.AppComponent.isAdmin = true;
		                          this.router.navigate(['/admin']);
		                        } else {
		                          this.AppComponent.isAdmin = false;
		                           this.router.navigate(['/dashboard/synthesis']);
		                        }
		                        this.loading = false;
		                    } else {
		                        this.loading = false;
		                    }
		                } else{
		                    this.loading = false;
		                }
		            }, err => {
		                this.toastr.error("L'email ou le mot de passe est incorrect.");
		            });
                } else {
                    this.toastr.error("La session de récupération de mot de passe à expiré.");
                    this.router.navigate(['/login']);
                }
            }, err => {
                this.toastr.error('Il y a eu une erreur, veuillez re-essayer plus tard.');
            });
        } else {
            this.toastr.error("Vous devez renseigner votre Nom, Prénom, Adresse e-mail et mot de passe.");
        }

    }

}
