import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

// App component
import { AppComponent } from '../../app.component';

// Providers
import { AuthService } from '../../providers';

@Component({
  	selector: 'app-login',
  	templateUrl: './login.html',
  	providers: [  ]
})
export class LoginPage implements OnInit {

  	public loading = false;
    user: object;

  	constructor(
      private authService: AuthService,
      public appComponent: AppComponent,
  		private formBuilder: FormBuilder,
      private toastr: ToastrService,
      private router: Router
  	) { 

  	}
  
  	ngOnInit() {
        this.user = this.formBuilder.group({
          	email: ['', Validators.required],
          	password: ['', Validators.required]
        });
  	}

    login(user) {
        if(user.value.email != null && user.value.email != '' && user.value.password != null && user.value.password != ''){
            this.loading = true;
            this.authService.auth(user.value).subscribe(result => {
                if(result.success == true){
                    result.token = 'Bearer '+result.token;
                    if (result && result.token) {
                        this.appComponent.connected = true;
                        localStorage.setItem('currentUser', JSON.stringify(result));
                        this.loading = false;
                        if(result.role === "admin"){
                          this.appComponent.isAdmin = true;
                          this.router.navigate(['/admin']);
                        } else {
                          this.appComponent.isAdmin = false;
                           this.router.navigate(['/dashboard/synthesis']);
                        }
                    } else {
                        this.loading = false;
                    }
                } else{
                    this.loading = false;
                }
            }, err => {
                this.toastr.error("L'email ou le mot de passe est incorrect.");
            });
        } else {
            this.toastr.error("Vous devez renseigner votre email et mot de passe.");
        }
    }
}