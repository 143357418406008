import { Component, OnInit, ViewChild } from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { CompareForm } from '../../../includes/compare-form/compare-form';
import { ToastrService } from 'ngx-toastr';

// Providers
import { ToolsService } from '../../../providers';

@Component({
  	selector: 'app-distribution-keys',
  	templateUrl: './distribution-keys.html',
  	providers: [  ],
  	animations: [  		
        // Déploiement tableaux
        trigger('expandTable', [
            transition(':enter', [
                style({
                	height:'65px',
                	overflow:'hidden'
                })
            ]),
            state('false', style({
                height: '65px',
                overflow:'hidden'
            })),
            state('true', style({
                height: '*',
                overflow:'hidden'
            })),
            transition('* => true', [
                style({height:'65px'}),
                animate(200, style({height:'*', overflow:'hidden'}))
            ]),
            transition('* => false', [
                style({height:'*'}),
                animate(200, style({height:'65px', overflow:'hidden'}))
            ])
        ]),
        trigger('expand', [
            transition(':enter', [
                style({
                	height:'0',
                	overflow:'hidden'
                }),
                animate(200, style({
                	height:'*',
                	overflow:'hidden'
                })) 
            ])
        ]),
  	]
})
export class DistributionKeysPage implements OnInit {

  	public loading = false;

    // Retour API
    objectKeys = Object.keys;

    data: any;
    compareData: any;

    // Formulaire de comparaison
    @ViewChild(CompareForm) compareForm;
    subscription: any;

  	// Toggle comparaison
  	comparison : boolean = false;

  	// Dépliage tableaux
  	greatDishExpand : boolean = false;
  	smallDishExpand : boolean = false;
  	shapeExpand : boolean = false;
  	spmExpand : boolean = false;
  	driedExpand : boolean = false;
    residentExpand : boolean = false;
  	totalExpand : boolean = false;

    dataReady: boolean = true;

  	constructor(
        private toolsService: ToolsService,
        private toastr: ToastrService
    ) { 
  
  	}
  
  	ngOnInit() {
    	this.loading = true;

      this.subscription = this.compareForm.getComparisonEmitter().subscribe(item => {
        this.toolsService.compare("distributionKeys", item.year, item.structure, item.volume).subscribe(resultCompare => {
          if(resultCompare.success && (item.year || item.structure || item.volume)) {
            this.compareData = resultCompare.data;
            this.compareForm.comparison = true;
          }
          else {
            this.compareData = "";
            this.compareForm.comparison = false;
            if(item.year || item.structure || item.volume) {
                this.toastr.error("Aucune comparaison à afficher");
            }
          }
        });
      });

      this.runData();
    }

    runData() {
      this.toolsService.me("distributionKeys", 2018).subscribe(result => {
        if(result.success){
          this.data = result.data;
          this.comparison = this.compareForm.comparison;
        } else {
            this.dataReady = false;
        }
      });
    }

    accordionName(category) {

        switch(category) {

            case "Grand plat" :
                return this.greatDishExpand;
            break;

            case "Petit plat" :
                return this.smallDishExpand;
            break;

            case "Forme" :
                return this.shapeExpand;
            break;

            case "SPM" :
                return this.spmExpand;
            break;

            case "Séché" :
                return this.driedExpand;
            break;

            case "Résidents" :
                return this.residentExpand;
            break;

            case "Total" :
                return this.totalExpand;
            break;
        }
    }

    switchAccordion(accordionName) {

        switch(accordionName) {

            case "Grand plat" :
                this.greatDishExpand = !this.greatDishExpand;
            break;

            case "Petit plat" :
                this.smallDishExpand = !this.smallDishExpand;
            break;

            case "Forme" :
                this.shapeExpand = !this.shapeExpand;
            break;

            case "SPM" :
                this.spmExpand = !this.spmExpand;
            break;

            case "Séché" :
                this.driedExpand = !this.driedExpand;
            break;

            case "Résidents" :
                this.residentExpand = !this.residentExpand;
            break;

            case "Total" :
                this.totalExpand = !this.totalExpand;
            break;
        }
    }

    accordionClass(accordionName) {

        switch(accordionName) {

            case "Grand plat" :
                if(this.greatDishExpand) {
                    return 'active';
                }
                else {
                    return '';
                }
            break;

            case "Petit plat" :
                if(this.smallDishExpand) {
                    return 'active';
                }
                else {
                    return '';
                }
            break;

            case "Forme" :
                if(this.shapeExpand) {
                    return 'active';
                }
                else {
                    return '';
                }
            break;

            case "SPM" :
                if(this.spmExpand) {
                    return 'active';
                }
                else {
                    return '';
                }
            break;

            case "Séché" :
                if(this.driedExpand) {
                    return 'active';
                }
                else {
                    return '';
                }
            break;

            case "Résidents" :
                if(this.residentExpand) {
                    return 'active';
                }
                else {
                    return '';
                }
            break;

            case "Total" :
                if(this.totalExpand) {
                    return 'active';
                }
                else {
                    return '';
                }
            break;
        }
    }
}
