import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Animations chiffres
import { CountoModule }  from 'angular2-counto';

// Click outside
import { ClickOutsideModule } from 'ng-click-outside';

// Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


// Pages
import { LoginPage } from './pages/login/login';
import { CheckResetPage } from './pages/password-reset/check-reset/check-reset';
import { ForgotPasswordPage } from './pages/password-reset/forgot-password/forgot-password';
import { NewPasswordPage } from './pages/password-reset/new-password/new-password';
import { NotFoundPage } from './pages/not-found/404';
import { AccountPage } from './pages/member/account/account';
import { ContactPage } from './pages/member/contact/contact';
import { MyCenterPage } from './pages/member/my-center/my-center';
import { ChargesPage } from './pages/dashboard/charges/charges';
import { ControlsPage } from './pages/dashboard/controls/controls';
import { DistributionKeysPage } from './pages/dashboard/distribution-keys/distribution-keys';
import { DriedPage } from './pages/dashboard/dried/dried';
import { DriedFoldedPage } from './pages/dashboard/dried-folded/dried-folded';
import { EffectivePage } from './pages/dashboard/effective/effective';
import { GreatDishPage } from './pages/dashboard/great-dish/great-dish';
import { ProductionEquipmentPage } from './pages/dashboard/production-equipment/production-equipment';
import { RatesAndNeedPerCustomerPage } from './pages/dashboard/rates-and-need-per-customer/rates-and-need-per-customer';
import { ResidentLinenPage } from './pages/dashboard/resident-linen/resident-linen';
import { ShapePage } from './pages/dashboard/shape/shape';
import { SmallDishPage } from './pages/dashboard/small-dish/small-dish';
import { SynthesisPage } from './pages/dashboard/synthesis/synthesis';
import { TonnageRecipePage } from './pages/dashboard/tonnage-recipe/tonnage-recipe';
import { ForumPage } from './pages/member/forum/forum';
import { ThreadPage } from './pages/member/forum/thread/thread';

// Admin page
import { AdminPage } from './pages/admin/dashboard';

// Includes
import { CompareForm } from './includes/compare-form/compare-form';

// Config URL
import { Config } from './app.config';

// Providers
import {
	AuthService,
	AdminService,
	MyCenterService,
	ToolsService,
	ForumCategoryService,
	ForumThreadService,
	ForumMessageService,
	ContactService,
	UserService
} from './providers';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

@NgModule({
  declarations: [
    AppComponent,
	LoginPage,
	CheckResetPage,
	ForgotPasswordPage,
	NewPasswordPage,
	NotFoundPage,
	AccountPage,
	ContactPage,
	MyCenterPage,
	ChargesPage,
	ControlsPage,
	DistributionKeysPage,
	DriedPage,
	DriedFoldedPage,
	EffectivePage,
	GreatDishPage,
	ProductionEquipmentPage,
	RatesAndNeedPerCustomerPage,
	ResidentLinenPage,
	ShapePage,
	SmallDishPage,
	SynthesisPage,
	TonnageRecipePage,
	CompareForm,
	ForumPage,
	ThreadPage,
	AdminPage
  ],
  imports: [
  	HttpModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CountoModule,
    ClickOutsideModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    })
  ],
  providers: [
	  Config, 
	  AuthService, 
	  AdminService,
	  MyCenterService,
	  UserService,
	  ToolsService,
	  ForumCategoryService,
	  ForumThreadService,
	  ForumMessageService,
	  ContactService,
	  CompareForm,
	  {
	  	provide: PERFECT_SCROLLBAR_CONFIG,
      	useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
