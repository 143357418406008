import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Config } from '../../app.config';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {

	constructor(
		private http: Http,
		private config: Config) {}
	
	
	// // Authentification
	// // Method : POST
	// // Route : /api/auth
	public auth(data) {

		let url = this.config.apiUrl + '/api/auth';
		// let token = this.AuthenticationService.getToken();

		let options  = new RequestOptions({});

		return this.http.post(url, data, options).pipe(
      map((result) => {
        return result.json();
      }))
	}

  // Récupération du token de l'utilisateur
  public getToken() {
     let currentUser = localStorage.getItem('currentUser');
     if(currentUser){
       let currentUserJson = JSON.parse(localStorage['currentUser']);
       let token = currentUserJson.token;
       return token;
    }
  }

  // Récupération du nom prénom et ID de l'utilisateur connecté
  public getAccount() {
     let account = [];
     let currentUser = localStorage.getItem('currentUser');
     let currentUserJson = JSON.parse(localStorage['currentUser']);
     account.push({membreID: currentUserJson.membreID, prenom: currentUserJson.prenom, nom: currentUserJson.nom});
     return account;
  }

  public passwordRecovery(data) {
    let url = this.config.apiUrl + '/api/reset/send';
    let headers = new Headers({"Content-type": "application/json"});
    let options = {headers: headers};
    return this.http.post(url, data, options).pipe(
      map((result) => {
        return result.json();
      })
    )
  }

  public checkReset(data) {
    let url = this.config.apiUrl + '/api/reset/check';
    let headers = new Headers({"Content-type": "application/json"});
    let options = {headers: headers};
    return this.http.post(url, data, options).pipe(
      map((result) => {
        return result.json();
      })
    )
  }

  public setNewPassword(data, userId, code) {
    let url = this.config.apiUrl + '/api/reset/setNewPassword/' + userId + '/' + code;
    let headers = new Headers({"Content-type": "application/json"});
    let options = {headers: headers};
    return this.http.post(url, data, options).pipe(
      map((result) => {
        return result.json();
      })
    )   
  }

}
