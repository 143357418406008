import { Component, OnInit, ViewChild } from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { CompareForm } from '../../../includes/compare-form/compare-form';
import { ToastrService } from 'ngx-toastr';

// Providers
import { ToolsService } from '../../../providers';

@Component({
  	selector: 'app-controls',
  	templateUrl: './controls.html',
  	providers: [  ],
  	animations: [  		
        // Déploiement tableaux
        trigger('expandTable', [
            transition(':enter', [
                style({
                	height:'65px',
                	overflow:'hidden'
                })
            ]),
            state('false', style({
                height: '65px',
                overflow:'hidden'
            })),
            state('true', style({
                height: '*',
                overflow:'hidden'
            })),
            transition('* => true', [
                style({height:'65px'}),
                animate(200, style({height:'*', overflow:'hidden'}))
            ]),
            transition('* => false', [
                style({height:'*'}),
                animate(200, style({height:'65px', overflow:'hidden'}))
            ])
        ]),
        trigger('expandTableCompare', [
            transition(':enter', [
                style({
                	height:'0',
                	overflow:'hidden'
                }),
                animate(200, style({
                	height:'65px',
                	overflow:'hidden'
                })) 
            ])
        ]),
  	]
})
export class ControlsPage implements OnInit {

  	public loading = false;

    // Retour API
    objectKeys = Object.keys;

    data: any;
    compareData: any;

    // Formulaire de comparaison
    @ViewChild(CompareForm) compareForm;
    subscription: any;

  	// Toggle comparaison
  	comparison : boolean = false;

  	// Dépliage tableaux
  	personnelExpand : boolean = false;
  	consumptionExpand : boolean = false;
  	otherChargesExpand : boolean = false;
  	amortizationExpand : boolean = false;

    dataReady: boolean = true;

  	constructor(
        private toolsService: ToolsService,
        private toastr: ToastrService
    ) { 
  		
  	}
  
  	ngOnInit() {
    	this.loading = true;

      this.subscription = this.compareForm.getComparisonEmitter().subscribe(item => {
        this.toolsService.compare("controls", item.year, item.structure, item.volume).subscribe(resultCompare => {
          if(resultCompare.success && (item.year || item.structure || item.volume)) {
            this.compareData = resultCompare.data;
            this.compareForm.comparison = true;
          }
          else {
            this.compareData = "";
            this.compareForm.comparison = false;
            if(item.year || item.structure || item.volume) {
                this.toastr.error("Aucune comparaison à afficher");
            }
          }
        });
      });

      this.runData();
    }

    runData() {
      this.toolsService.me("controls", 2018).subscribe(result => {
        if(result.success){
          this.data = result.data;
          this.comparison = this.compareForm.comparison;
        } else {
            this.dataReady = false;
        }
      });
    }

    accordionName(category) {

        switch(category) {

            case "Personnel" :
                return this.personnelExpand;
            break;

            case "Consommation" :
                return this.consumptionExpand;
            break;

            case "Autres charges" :
                return this.otherChargesExpand;
            break;

            case "Amortissements" :
                return this.amortizationExpand;
            break;
        }
    }

    switchAccordion(accordionName) {

        switch(accordionName) {

            case "Personnel" :
                this.personnelExpand = !this.personnelExpand;
            break;

            case "Consommation" :
                this.consumptionExpand = !this.consumptionExpand;
            break;

            case "Autres charges" :
                this.otherChargesExpand = !this.otherChargesExpand;
            break;

            case "Amortissements" :
                this.amortizationExpand = !this.amortizationExpand;
            break;
        }
    }

    accordionClass(accordionName) {

        switch(accordionName) {

            case "Personnel" :
                if(this.personnelExpand) {
                    return 'active';
                }
                else {
                    return '';
                }
            break;

            case "Consommation" :
                if(this.consumptionExpand) {
                    return 'active';
                }
                else {
                    return '';
                }
            break;

            case "Autres charges" :
                if(this.otherChargesExpand) {
                    return 'active';
                }
                else {
                    return '';
                }
            break;

            case "Amortissements" :
                if(this.amortizationExpand) {
                    return 'active';
                }
                else {
                    return '';
                }
            break;
        }
    }
}
