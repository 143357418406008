import { Component, OnInit, ViewChild } from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { CompareForm } from '../../../includes/compare-form/compare-form';
import * as ChartsJS from '../../../../assets/libraries/chart.bundle.min';
import { ToastrService } from 'ngx-toastr';

// Providers
import { ToolsService } from '../../../providers';

@Component({
  	selector: 'app-production-equipment',
  	templateUrl: './production-equipment.html',
  	providers: [  ],
  	animations: [  		
        // Déploiement tableaux
        trigger('expand', [
            transition(':enter', [
                style({
                	  height:'0',
                	  overflow:'hidden'
                }),
                animate(200, style({
                	  height:'*',
                	  overflow:'hidden'
                })) 
            ]),
            transition(':leave', [
                style({
                	  height:'*',
                	  overflow:'hidden'
                }),
                animate(200, style({
                	  height:'0',
                	  overflow:'hidden'
                })) 
            ])
        ]),
        // Déploiement charts
        trigger('expandChart', [
            transition(':enter', [
                style({height:0, overflow:'hidden', 'margin-bottom':0})
            ]),
            state('false', style({
                height: 0,
                overflow:'hidden',
                'margin-bottom': 0,
            })),
            state('true', style({
                height: '*',
                overflow:'visible',
                'margin-bottom': '30px',
            })),
            transition('* => true', [
                style({height:0, overflow:'hidden'}),
                animate(200, style({height:'*', overflow:'visible', 'margin-bottom':'30px'}))
            ])
        ]),
  	]
})
export class ProductionEquipmentPage implements OnInit {

  	public loading = false;

    // Retour API
    objectKeys = Object.keys;

    data: any;
    compareData: any;

    // Formulaire de comparaison
    @ViewChild(CompareForm) compareForm;
    subscription: any;

  	// Toggle comparaison
  	comparison : boolean = false;

    /* Charts variables */
    stackedChartTooltips : any = [];
    doughnutChartTooltips : any = [];
    optionsStackedBar : any = [];
    typeStackedBar : any = '';
    optionsDoughnut : any = [];
    typeDoughnut : any = '';
    // Stackedbar keys chart
    keysChart_elem : any = '';
    keysChartComparison_elem : any = '';
    keysChartData : any = [];
    keysChartComparisonData : any = [];
    keysChart : any = '';
    keysChartComparison : any = '';
    // Doughnut washing chart
    washingChart_elem : any = '';
    washingChartComparison_elem : any = '';
    washingChartData : any = [];
    washingChartComparisonData : any = [];
    washingChart : any = '';
    washingChartComparison : any = '';

    // Toggle button
    amortizationTTC : boolean = false;

    dataReady: boolean = true;


  	constructor(
        private toolsService: ToolsService,
        private toastr: ToastrService
    ) { 
  
  	}
  
  	ngOnInit() {
    	this.loading = true;

      // Lorsque la comparaison est déclenchée dans le composant compareForm, on trigger la fonction compareCharts()
      this.subscription = this.compareForm.getComparisonEmitter().subscribe(item => {
        this.toolsService.compare("productionEquipment", item.year, item.structure, item.volume).subscribe(resultCompare => {
          if(resultCompare.success && (item.year || item.structure || item.volume)) {
            this.compareData = resultCompare.data;
            this.compareForm.comparison = true;
            this.compareCharts();
          }
          else {
            this.compareData = "";
            this.compareForm.comparison = false;
            if(item.year || item.structure || item.volume) {
                this.toastr.error("Aucune comparaison à afficher");
            }
            this.initialCharts();
          }
        });
      });

      this.runData();
    }

    runData() {
      this.toolsService.me("productionEquipment", 2018).subscribe(result => {
        if(result.success){
          this.data = result.data;
          this.comparison = this.compareForm.comparison;
          // Création des graphiques initiaux
          this.initialCharts();
        } else {
            this.dataReady = false;
        }
      });
    }

  	makeChart(chartElement, type, data, options) {

  		return new ChartsJS(chartElement, {
		    type: type,
		    data: data,
		    options: options
		  });
  	}

  	updateChart(chart, dataReplace, dataReplaceComparison) {
  		chart.data.datasets[0].data = dataReplace;

  		if(chart.data.datasets.length > 1) {
	  		chart.data.datasets[1].data = dataReplaceComparison;
	  	}

  		chart.update();
  	}

  	addDataset(chart, dataset) {

  		if(chart.data.datasets.length < 2) {
			chart.data.datasets.push(dataset);
			chart.update();
		}
  	}


  	/* 
  	 *  Cette fonction gère la création des graphiques initiaux
  	 */
  	initialCharts() {

        /* STACKED BAR CHARTS */
        this.stackedChartTooltips = {
            callbacks: {
                label: function(tooltipItem, data) {
                    return " " + data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',') + "%";
                }
            },
            backgroundColor: '#000',
            titleFontSize: 0,
            titleFontColor: '#0066ff',
            bodyFontColor: '#fff',
            bodyFontSize: 12,
            displayColors: true
        }

        this.optionsStackedBar = {
            legend: {
              display: false
            },
            scales: {
                xAxes: [{      
                    stacked: true,       
                    barThickness: 25,
                    gridLines: {
                        display:false
                    },
                    ticks: {
                        fontSize: 15,
                        fontColor: '#a1a1a1',
                        fontFamily: 'Muli'
                    }
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        beginAtZero:true,
                        fontSize: 12,
                        fontColor: '#a1a1a1',
                        fontFamily: 'Muli'
                    },
                    gridLines: {
                        display:false
                    }
                }]
            },
            tooltips: this.stackedChartTooltips
        }
        this.typeStackedBar = 'bar';

        // KEYS CHART
        this.keysChart_elem = document.getElementById("stackedbar_keys") as HTMLCanvasElement;
        this.keysChartData = {
            labels: [["Réception", "accrochage"], "Tri lavage", "Finition", "Distribution", "Bâtiment"],
            datasets: [{
                data: [
                    this.data.amortizationCharges.GP.hanging,
                    this.data.amortizationCharges.GP.washingSorting,
                    this.data.amortizationCharges.GP.finishing,
                    this.data.amortizationCharges.GP.delivery,
                    this.data.amortizationCharges.GP.building
                ], // Grand plat
                backgroundColor: '#41bbe7',
            },
            {
                data: [
                    this.data.amortizationCharges.PP.hanging,
                    this.data.amortizationCharges.PP.washingSorting,
                    this.data.amortizationCharges.PP.finishing,
                    this.data.amortizationCharges.PP.delivery,
                    this.data.amortizationCharges.PP.building
                ], // Petit plat
                backgroundColor: '#ff003c',
            },
            {
                data: [
                    this.data.amortizationCharges.shape.hanging,
                    this.data.amortizationCharges.shape.washingSorting,
                    this.data.amortizationCharges.shape.finishing,
                    this.data.amortizationCharges.shape.delivery,
                    this.data.amortizationCharges.shape.building
                ], // Forme
                backgroundColor: '#ffd800',
            },
            {
                data: [
                    this.data.amortizationCharges.driedFolded.hanging,
                    this.data.amortizationCharges.driedFolded.washingSorting,
                    this.data.amortizationCharges.driedFolded.finishing,
                    this.data.amortizationCharges.driedFolded.delivery,
                    this.data.amortizationCharges.driedFolded.building
                ], // Seché plié
                backgroundColor: '#f000ff',
            },
            {
                data: [
                    this.data.amortizationCharges.dried.hanging,
                    this.data.amortizationCharges.dried.washingSorting,
                    this.data.amortizationCharges.dried.finishing,
                    this.data.amortizationCharges.dried.delivery,
                    this.data.amortizationCharges.dried.building
                ], // Séché
                backgroundColor: '#68cbb5',
            },
            {
                data: [
                    this.data.amortizationCharges.residents.hanging,
                    this.data.amortizationCharges.residents.washingSorting,
                    this.data.amortizationCharges.residents.finishing,
                    this.data.amortizationCharges.residents.delivery,
                    this.data.amortizationCharges.residents.building
                ], // Résidents
                backgroundColor: '#22216d',
            }]
        }
        this.keysChart = this.makeChart(this.keysChart_elem, this.typeStackedBar, this.keysChartData, this.optionsStackedBar);

        /* DOUGHNUT CHARTS */
        // DOUGHNUT OPTIONS
        this.doughnutChartTooltips = {
            callbacks: {
                label: function(tooltipItem, data) {
                    return " " + data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',') + " €";
                }
            },
            backgroundColor: '#000',
            titleFontSize: 0,
            titleFontColor: '#0066ff',
            bodyFontColor: '#fff',
            bodyFontSize: 12,
            displayColors: true
        }

        this.optionsDoughnut = {
            legend: {
                display: false,
            },
            aspectRatio: 1,
            cutoutPercentage: 68,
            tooltips: this.doughnutChartTooltips
        }
        this.typeDoughnut = 'doughnut';

        // DOUGHNUT WASHING CHART
        this.washingChart_elem = document.getElementById("doughnut_washing") as HTMLCanvasElement;
        this.washingChartData = {
            datasets: [{
                data: [
                    this.data.washingEnd.items.press.value,
                    this.data.washingEnd.items.folder.value,
                    this.data.washingEnd.items.bagger.value,
                    this.data.washingEnd.items.DAV.value,
                    this.data.washingEnd.items.clalandre.value
                ],
                backgroundColor: ['#41bbe7', '#ff003c', '#68cbb5', '#22216d', '#f000ff'],
                borderWidth: 0
            }],
            labels: [
                'Presse 2 Navettes',
                'Plieuse',
                'Ensacheuse',
                'Tunnel DAV',
                'Clalandre'
            ]
        }
        this.washingChart = this.makeChart(this.washingChart_elem, this.typeDoughnut, this.washingChartData, this.optionsDoughnut);
  	}


  	/*
  	 *  Cette fonction gère la création des graphiques comparatifs
  	 */
  	compareCharts() {

        // KEYS CHART COMPARISON
        this.keysChartComparison_elem = document.getElementById("stackedbar_keys_compare") as HTMLCanvasElement;
        this.keysChartComparisonData = {
            labels: [["Réception", "accrochage"], "Tri lavage", "Finition", "Distribution", "Bâtiment"],
            datasets: [{
                data: [
                    this.compareData.amortizationCharges.GP.hanging,
                    this.compareData.amortizationCharges.GP.washingSorting,
                    this.compareData.amortizationCharges.GP.finishing,
                    this.compareData.amortizationCharges.GP.delivery,
                    this.compareData.amortizationCharges.GP.building
                ], // Grand plat
                backgroundColor: '#41bbe7',
            },
            {
                data: [
                    this.compareData.amortizationCharges.PP.hanging,
                    this.compareData.amortizationCharges.PP.washingSorting,
                    this.compareData.amortizationCharges.PP.finishing,
                    this.compareData.amortizationCharges.PP.delivery,
                    this.compareData.amortizationCharges.PP.building
                ], // Petit plat
                backgroundColor: '#ff003c',
            },
            {
                data: [
                    this.compareData.amortizationCharges.shape.hanging,
                    this.compareData.amortizationCharges.shape.washingSorting,
                    this.compareData.amortizationCharges.shape.finishing,
                    this.compareData.amortizationCharges.shape.delivery,
                    this.compareData.amortizationCharges.shape.building
                ], // Forme
                backgroundColor: '#ffd800',
            },
            {
                data: [
                    this.compareData.amortizationCharges.driedFolded.hanging,
                    this.compareData.amortizationCharges.driedFolded.washingSorting,
                    this.compareData.amortizationCharges.driedFolded.finishing,
                    this.compareData.amortizationCharges.driedFolded.delivery,
                    this.compareData.amortizationCharges.driedFolded.building
                ], // Seché plié
                backgroundColor: '#f000ff',
            },
            {
                data: [
                    this.compareData.amortizationCharges.dried.hanging,
                    this.compareData.amortizationCharges.dried.washingSorting,
                    this.compareData.amortizationCharges.dried.finishing,
                    this.compareData.amortizationCharges.dried.delivery,
                    this.compareData.amortizationCharges.dried.building
                ], // Séché
                backgroundColor: '#68cbb5',
            },
            {
                data: [
                    this.compareData.amortizationCharges.residents.hanging,
                    this.compareData.amortizationCharges.residents.washingSorting,
                    this.compareData.amortizationCharges.residents.finishing,
                    this.compareData.amortizationCharges.residents.delivery,
                    this.compareData.amortizationCharges.residents.building
                ], // Résidents
                backgroundColor: '#22216d',
            }]
        }
        this.keysChartComparison = this.makeChart(this.keysChartComparison_elem, this.typeStackedBar, this.keysChartComparisonData, this.optionsStackedBar);

        // DOUGHNUT WASHING CHART COMPARISON
        this.washingChartComparison_elem = document.getElementById("doughnut_washing_compare") as HTMLCanvasElement;
        this.washingChartComparisonData = {
            datasets: [{
                data: [
                    this.compareData.washingEnd.items.press.value,
                    this.compareData.washingEnd.items.folder.value,
                    this.compareData.washingEnd.items.bagger.value,
                    this.compareData.washingEnd.items.DAV.value,
                    this.compareData.washingEnd.items.clalandre.value
                ],
                backgroundColor: ['#41bbe7', '#ff003c', '#68cbb5', '#22216d', '#f000ff'],
                borderWidth: 0
            }],
            labels: [
                'Presse 2 Navettes',
                'Plieuse',
                'Ensacheuse',
                'Tunnel DAV',
                'Clalandre'
            ]
        }
        this.washingChartComparison = this.makeChart(this.washingChartComparison_elem, this.typeDoughnut, this.washingChartComparisonData, this.optionsDoughnut);
  	}
}
