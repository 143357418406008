import { Component, OnInit } from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';

// Providers
import { ContactService } from '../../../providers';

@Component({
  	selector: 'app-contact',
  	templateUrl: './contact.html',
    animations: [
        // Apparition popup
        trigger('fadeIn', [
            transition(':enter', [
                style({
                    opacity:'0',
                }),
                animate(200, style({
                    opacity:'1',
                })) 
            ])
        ]),
        trigger('slideIn', [
            transition(':enter', [
                style({
                    transform: 'translateY(25vh)',
                    opacity: '0'
                }),
                animate(200, style({
                    transform: 'translateY(0)',
                    opacity: '1'
                })) 
            ])
        ]),
        trigger('fadeOut', [
            transition(':leave', [
                style({
                    opacity:'1',
                }),
                animate(200, style({
                    opacity:'0',
                })) 
            ])
        ])
    ]
})
export class ContactPage implements OnInit {

  	public loading = false;
    contact: any;
    contacts: any;

    dialog: boolean = false;

  	constructor(
        private contactService: ContactService,
  		private formBuilder: FormBuilder,
  	) { 
  
      }
      
  
  	ngOnInit() {
    	this.loading = true;

        this.contact = this.formBuilder.group({
         	object: ['', Validators.required],
         	message: ['', Validators.required]
        });


        this.getContacts();

        
        
    }

      
    getContacts() {

        this.contactService.getAll().subscribe(result => {
            if (result.success) {
                this.contacts = result.data;
            }
        });

    }

    openDialog() {
    	this.dialog = true;
    }

    closeDialog() {
    	this.dialog = false;
    }

    send(contact) {
        this.loading = true;
        
        this.contactService.CreateNew(contact.value).subscribe(result => {
            if(result.success){
                this.openDialog();
                this.getContacts();
                this.contact.reset();
            }
        });

    }
}
