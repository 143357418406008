import { Component, OnInit, ViewChild } from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { CompareForm } from '../../../includes/compare-form/compare-form';
import * as ChartsJS from '../../../../assets/libraries/chart.bundle.min';
import { ToastrService } from 'ngx-toastr';

// Providers
import { ToolsService } from '../../../providers';

@Component({
  	selector: 'app-charges',
  	templateUrl: './charges.html',
  	providers: [  ],
  	animations: [  		
        // Déploiement tableaux
        trigger('expand', [
            transition(':enter', [
                style({
                	  height:'0',
                	  overflow:'hidden'
                }),
                animate(200, style({
                	  height:'*',
                	  overflow:'hidden'
                })) 
            ])
        ]),
        // Déploiement charts
        trigger('expandChart', [
            transition(':enter', [
                style({height:0, overflow:'hidden', 'margin-bottom':0})
            ]),
            state('false', style({
                height: 0,
                overflow:'hidden',
                'margin-bottom': 0,
            })),
            state('true', style({
                height: '*',
                overflow:'visible',
                'margin-bottom': '30px',
            })),
            transition('* => true', [
                style({height:0, overflow:'hidden'}),
                animate(200, style({height:'*', overflow:'visible', 'margin-bottom':'30px'}))
            ])
        ]),
        // Mega tableau
        trigger('megaTable', [
            state('false', style({
                width: '142px'
            })),
            state('true', style({
                width: '430px'
            })),
            transition('false => true', [
                style({width:'142px'}),
                animate(200, style({width:'430px'}))
            ]),
            transition('true => false', [
                style({width:'430px'}),
                animate(200, style({width:'142px'}))
            ])
        ]),
        trigger('megaTableTitle', [
            state('false', style({
                width: '142px'
            })),
            state('true', style({
                width: '430px'
            })),
            transition('false => true', [
                style({width:'142px'}),
                animate(200, style({width:'430px'}))
            ]),
            transition('true => false', [
                style({width:'430px'}),
                animate(200, style({width:'142px'}))
            ])
        ])
  	]
})
export class ChargesPage implements OnInit {

  	public loading = false;

    // Retour API
    objectKeys = Object.keys;

    data: any;
    compareData: any;

    // Formulaire de comparaison
    @ViewChild(CompareForm) compareForm;
    subscription: any;

  	// Toggle comparaison
  	comparison : boolean = false;

  	/* Charts variables */
    chartTooltips : any = [];
  	optionsBarVertical : any = [];
  	typeBarVertical : any = '';
  	optionsBarHorizontal : any = [];
  	typeBarHorizontal : any = '';
  	// Vertical bar Unit chart
  	unitChart_elem : any = '';
  	unitChartData : any = [];
  	unitChartDataComparison : any = [];
  	unitChart : any = '';
  	// Vertical bar Total chart
  	totalChart_elem : any = '';
  	totalChartData : any = [];
  	totalChartDataComparison : any = [];
  	totalChart : any = '';
  	// Charges chart
  	chargesChart_elem : any = '';
  	chargesChartDataHT : any = [];
  	chargesChartDataHTComparison : any = [];
  	chargesChartDataTTC : any = [];
  	chargesChartDataTTCComparison : any = [];
  	dataChargesChartHT : any = [];
  	dataChargesChart : any = [];
  	chargesChart : any = '';

    // Charts toggle buttons
    chargesTTC : boolean = false;

    // Tableau Déploiement
    waterActive: boolean = true;
    vaporActive: boolean = false;
    productActive: boolean = false;
    waterActiveCompare: boolean = true;
    vaporActiveCompare: boolean = false;
    productActiveCompare: boolean = false;

    dataReady: boolean = true;

  	constructor(
        private toolsService: ToolsService,
        private toastr: ToastrService
    ) { 
  
  	}
  
 	  ngOnInit() {
    	this.loading = true;

      // Lorsque la comparaison est déclenchée dans le composant compareForm, on trigger la fonction compareCharts()
      this.subscription = this.compareForm.getComparisonEmitter().subscribe(item => {
        this.toolsService.compare("loads", item.year, item.structure, item.volume).subscribe(resultCompare => {
          if(resultCompare.success && (item.year || item.structure || item.volume)) {
            this.compareData = resultCompare.data;
            this.compareForm.comparison = true;
            this.compareCharts();
          }
          else {
            this.compareData = "";
            this.compareForm.comparison = false;
            if(item.year || item.structure || item.volume) {
                this.toastr.error("Aucune comparaison à afficher");
            }
            this.initialCharts();
          }
        });
      });

      this.runData();
    }

    runData() {
      this.toolsService.me("loads", 2018).subscribe(result => {
        console.log(result);
        if(result.success){
          this.data = result.data;
          this.comparison = this.compareForm.comparison;
          // Création des graphiques initiaux
          this.initialCharts();
        } else {
          this.dataReady = false;
        }
      });
    }

  	makeChart(chartElement, type, data, options) {

  		return new ChartsJS(chartElement, {
		    type: type,
		    data: data,
		    options: options
		  });
  	}

  	updateChart(chart, dataReplace, dataReplaceComparison) {
  		chart.data.datasets[0].data = dataReplace;

  		if(chart.data.datasets.length > 1) {
	  		chart.data.datasets[1].data = dataReplaceComparison;
	  	}

  		chart.update();
  	}

  	addDataset(chart, dataset) {

  		if(chart.data.datasets.length < 2) {
  			chart.data.datasets.push(dataset);
  			chart.update();
  		}
  	}


  	/* 
  	 *  Cette fonction gère la création des graphiques initiaux
  	 */
  	initialCharts() {

        this.chartTooltips = {
            callbacks: {
                label: function(tooltipItem, data) {
                    return " " + data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',') + " €";
                }
            },
            backgroundColor: '#000',
            titleFontSize: 0,
            titleFontColor: '#0066ff',
            bodyFontColor: '#fff',
            bodyFontSize: 12,
            displayColors: true
        }

  		/* VERTICAL BAR CHARTS */
		// VERTICAL BAR CHART OPTIONS
        this.optionsBarVertical = {
		    legend: {
		    	display: false
		    },
		    scales: {
		    	xAxes: [{	        		
					barThickness: 25,
		            gridLines: {
		                display:false
		            },
      				ticks: {
		                fontSize: 15,
		                fontColor: '#a1a1a1',
		                fontFamily: 'Muli'
      				}
		    	}],
		        yAxes: [{
		            ticks: {
		                beginAtZero:true,
		                fontSize: 12,
		                fontColor: '#a1a1a1',
		                fontFamily: 'Muli'
		            },
		            gridLines: {
		                display:false
		            }
		        }]
		    },
            tooltips: this.chartTooltips
		}
		this.typeBarVertical = 'bar';

		// UNIT BAR CHART
	    this.unitChart_elem = document.getElementById("chart_unit") as HTMLCanvasElement;
		this.unitChartData = {
		    labels: ["Eau", "Énergie (gaz)", ["Produits", "lessiviels"]],
		    datasets: [{
		        data: [
                    this.data.consumptions.items.water.unitPrice,
                    this.data.consumptions.items.energy.unitPrice,
                    this.data.consumptions.items.products.unitPrice
                ],
		        backgroundColor: '#41bbe7',
		    }]
		}
		this.unitChart = this.makeChart(this.unitChart_elem, this.typeBarVertical, this.unitChartData, this.optionsBarVertical);

		// TOTAL BAR CHART
	    this.totalChart_elem = document.getElementById("chart_total") as HTMLCanvasElement;
		this.totalChartData = {
		    labels: ["Eau", "Énergie (gaz)", ["Produits", "lessiviels"]],
		    datasets: [{
		        data: [                
                    this.data.consumptions.items.water.amount,
                    this.data.consumptions.items.energy.amount,
                    this.data.consumptions.items.products.amount
                ],
		        backgroundColor: '#41bbe7',
		    }]
		}
		this.totalChart = this.makeChart(this.totalChart_elem, this.typeBarVertical, this.totalChartData, this.optionsBarVertical);

        /* HORIZONTAL BAR CHARTS */
        // HORIZONTAL BAR CHART OPTIONS
        this.optionsBarHorizontal = {
            legend: {
                display: false
            },
            scales: {
                yAxes: [{                   
                    barThickness: 20,
                    gridLines: {
                        display:false
                    },
                    afterFit: function(scaleInstance) {
                        scaleInstance.width = 170;
                    },
                    ticks: {
                        fontSize: 13,
                        fontColor: '#a1a1a1',
                        fontFamily: 'Muli'
                    }
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero:true,
                        fontSize: 12,
                        fontColor: '#a1a1a1',
                        fontFamily: 'Muli'
                    },
                    gridLines: {
                        display:false
                    },
                    position: 'top'
                }]
            },
            animation: {
                duration: 500
            },
            tooltips: this.chartTooltips
        }
        this.typeBarHorizontal = 'horizontalBar';

        // CHARGES BAR CHART
        this.chargesChart_elem = document.getElementById("chart_charges") as HTMLCanvasElement;
        this.chargesChartDataHT = [
            this.data.otherCharges.items.laundryPurchase.HT,
            this.data.otherCharges.items.electricity.HT,
            this.data.otherCharges.items.vehicleRent.HT,
            this.data.otherCharges.items.factorySupplies.HT,
            this.data.otherCharges.items.fuel.HT,
            this.data.otherCharges.items.administrativeSupplies.HT,
            this.data.otherCharges.items.spareParts.HT,
            this.data.otherCharges.items.smallEquipment.HT,
            this.data.otherCharges.items.guarantees.HT,
            this.data.otherCharges.items.rentals.HT,
            this.data.otherCharges.items.externalServices.HT,
            this.data.otherCharges.items.buildingMaintenance.HT,
            this.data.otherCharges.items.otherPayments.HT,
            this.data.otherCharges.items.cleaning.HT
        ];
        this.chargesChartDataTTC = [
            this.data.otherCharges.items.laundryPurchase.TTC,
            this.data.otherCharges.items.electricity.TTC,
            this.data.otherCharges.items.vehicleRent.TTC,
            this.data.otherCharges.items.factorySupplies.TTC,
            this.data.otherCharges.items.fuel.TTC,
            this.data.otherCharges.items.administrativeSupplies.TTC,
            this.data.otherCharges.items.spareParts.TTC,
            this.data.otherCharges.items.smallEquipment.TTC,
            this.data.otherCharges.items.guarantees.TTC,
            this.data.otherCharges.items.rentals.TTC,
            this.data.otherCharges.items.externalServices.TTC,
            this.data.otherCharges.items.buildingMaintenance.TTC,
            this.data.otherCharges.items.otherPayments.TTC,
            this.data.otherCharges.items.cleaning.TTC
        ];
        this.dataChargesChart =  {
            labels: ["Achats de linge", "Électricité", "Location véhicules", "Fournitures usine", "Carburant", ["Fournitures", "administratives"], "Pièces détachées", "Petit matériel", "Assurances", "Services extérieurs", "Entretien bâtiment", "Autres rémunérations", "Prestation ménage"],
            datasets: [{
                data: this.chargesChartDataHT,
                backgroundColor: '#41bbe7',
            }]
        }
        this.chargesChart = this.makeChart(this.chargesChart_elem, this.typeBarHorizontal, this.dataChargesChart, this.optionsBarHorizontal);
  	}


  	/*
  	 *  Cette fonction gère la création des graphiques comparatifs
  	 */
  	compareCharts() {

    		// UNIT BAR CHART COMPARISON
    		this.unitChartDataComparison = {
    			data: [
                    this.compareData.consumptions.items.water.unitPrice,
                    this.compareData.consumptions.items.energy.unitPrice,
                    this.compareData.consumptions.items.products.unitPrice
                ],
    		    backgroundColor: '#ff2c59'
    		};
    		this.addDataset(this.unitChart, this.unitChartDataComparison);

    		// TOTAL BAR CHART COMPARISON
    		this.totalChartDataComparison = {
    			data: [
                    this.compareData.consumptions.items.water.amount,
                    this.compareData.consumptions.items.energy.amount,
                    this.compareData.consumptions.items.products.amount
                ],
    		    backgroundColor: '#ff2c59'
    		};
    		this.addDataset(this.totalChart, this.totalChartDataComparison);

        // CHARGES BAR CHART
        this.chargesChartDataHTComparison = [
            this.compareData.otherCharges.items.laundryPurchase.HT,
            this.compareData.otherCharges.items.electricity.HT,
            this.compareData.otherCharges.items.vehicleRent.HT,
            this.compareData.otherCharges.items.factorySupplies.HT,
            this.compareData.otherCharges.items.fuel.HT,
            this.compareData.otherCharges.items.administrativeSupplies.HT,
            this.compareData.otherCharges.items.spareParts.HT,
            this.compareData.otherCharges.items.smallEquipment.HT,
            this.compareData.otherCharges.items.guarantees.HT,
            this.compareData.otherCharges.items.rentals.HT,
            this.compareData.otherCharges.items.externalServices.HT,
            this.compareData.otherCharges.items.buildingMaintenance.HT,
            this.compareData.otherCharges.items.otherPayments.HT,
            this.compareData.otherCharges.items.cleaning.HT
        ];
        this.chargesChartDataTTCComparison = [
            this.compareData.otherCharges.items.laundryPurchase.TTC,
            this.compareData.otherCharges.items.electricity.TTC,
            this.compareData.otherCharges.items.vehicleRent.TTC,
            this.compareData.otherCharges.items.factorySupplies.TTC,
            this.compareData.otherCharges.items.fuel.TTC,
            this.compareData.otherCharges.items.administrativeSupplies.TTC,
            this.compareData.otherCharges.items.spareParts.TTC,
            this.compareData.otherCharges.items.smallEquipment.TTC,
            this.compareData.otherCharges.items.guarantees.TTC,
            this.compareData.otherCharges.items.rentals.TTC,
            this.compareData.otherCharges.items.externalServices.TTC,
            this.compareData.otherCharges.items.buildingMaintenance.TTC,
            this.compareData.otherCharges.items.otherPayments.TTC,
            this.compareData.otherCharges.items.cleaning.TTC
        ];
        var chargesChartDataHTDatasetComparison = {
            data: this.chargesChartDataHTComparison,
            backgroundColor: '#ff2c59',
        };
        this.addDataset(this.chargesChart, chargesChartDataHTDatasetComparison);

        // RESET HORIZONTAL BAR CHARTS TO HT
        this.updateChart(this.chargesChart, this.chargesChartDataHT, this.chargesChartDataHTComparison);
        this.chargesTTC = false;
  	}
}
