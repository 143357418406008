import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuard } from './_guards/index';

import { LoginPage } from './pages/login/login';
import { CheckResetPage } from './pages/password-reset/check-reset/check-reset';
import { ForgotPasswordPage } from './pages/password-reset/forgot-password/forgot-password';
import { NewPasswordPage } from './pages/password-reset/new-password/new-password';
import { NotFoundPage } from './pages/not-found/404';
import { AccountPage } from './pages/member/account/account';
import { ContactPage } from './pages/member/contact/contact';
import { MyCenterPage } from './pages/member/my-center/my-center';
import { ChargesPage } from './pages/dashboard/charges/charges';
import { ControlsPage } from './pages/dashboard/controls/controls';
import { DistributionKeysPage } from './pages/dashboard/distribution-keys/distribution-keys';
import { DriedPage } from './pages/dashboard/dried/dried';
import { DriedFoldedPage } from './pages/dashboard/dried-folded/dried-folded';
import { EffectivePage } from './pages/dashboard/effective/effective';
import { GreatDishPage } from './pages/dashboard/great-dish/great-dish';
import { ProductionEquipmentPage } from './pages/dashboard/production-equipment/production-equipment';
import { RatesAndNeedPerCustomerPage } from './pages/dashboard/rates-and-need-per-customer/rates-and-need-per-customer';
import { ResidentLinenPage } from './pages/dashboard/resident-linen/resident-linen';
import { ShapePage } from './pages/dashboard/shape/shape';
import { SmallDishPage } from './pages/dashboard/small-dish/small-dish';
import { SynthesisPage } from './pages/dashboard/synthesis/synthesis';
import { TonnageRecipePage } from './pages/dashboard/tonnage-recipe/tonnage-recipe';
import { ForumPage } from './pages/member/forum/forum';
import { ThreadPage } from './pages/member/forum/thread/thread';
import { AdminPage } from './pages/admin/dashboard';

const routes: Routes = [
    // Common
	{ path: 'login', component: LoginPage },
    { path: 'reset/password', component: ForgotPasswordPage },
    { path: 'reset/password/check/:id/:email', component: CheckResetPage },
    { path: 'reset/password/new/:id/:email/:code', component: NewPasswordPage },
    { path: '404', component: NotFoundPage },
    
    // Admin
    { path: 'admin', component: AdminPage },

    // Member
    { path: 'account', component: AccountPage },
    { path: 'contact', component: ContactPage },
    { path: 'my-center', component: MyCenterPage },
    { path: 'forum', component: ForumPage },
    { path: 'forum/thread/:id', component: ThreadPage },

    // Dashboard
    { path: 'dashboard/charges', component: ChargesPage },
    { path: 'dashboard/controls', component: ControlsPage },
    { path: 'dashboard/distribution-keys', component: DistributionKeysPage },
    { path: 'dashboard/dried', component: DriedPage },
    { path: 'dashboard/dried-folded', component: DriedFoldedPage },
    { path: 'dashboard/effective', component: EffectivePage },
    { path: 'dashboard/great-dish', component: GreatDishPage },
    { path: 'dashboard/production-equipment', component: ProductionEquipmentPage },
    { path: 'dashboard/rates-and-need-per-customer', component: RatesAndNeedPerCustomerPage },
    { path: 'dashboard/resident-linen', component: ResidentLinenPage },
    { path: 'dashboard/shape', component: ShapePage },
    { path: 'dashboard/small-dish', component: SmallDishPage },
    { path: 'dashboard/synthesis', component: SynthesisPage },
    { path: 'dashboard/tonnage-recipe', component: TonnageRecipePage }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 


}