import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Config } from '../../app.config';
import { AuthService } from '../auth';
import { map } from 'rxjs/operators';

@Injectable()
export class ToolsService {

	constructor(
		private http: Http,
		private config: Config,
		private authService: AuthService
	) {

	}

	public me(what, year) {

		let url = this.config.apiUrl + '/api/tools/me/' + what + '/' + year;
		let token = this.authService.getToken();

		let headers = new Headers({"Authorization": token, "Content-Type": "application/json"});
		let options  = new RequestOptions({ headers: headers });

		return this.http.get(url, options).pipe(
		  map((result) => {
		  	console.log(result);
		    return result.json();
		  })
		)
	}

	public compare(what, year, structure, volumes) {

		let url = this.config.apiUrl + '/api/tools/compare/' + what + '/' + year + '/' + structure + '/' + volumes;
		let token = this.authService.getToken();

		let headers = new Headers({"Authorization": token, "Content-Type": "application/json"});
		let options  = new RequestOptions({ headers: headers });

		return this.http.get(url, options).pipe(
		  map((result) => {
		  	console.log(result);
		    return result.json();
		  })
		)
	}

}
