import { Component, OnInit } from '@angular/core';
// import { AuthService } from './providers/index';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  displayMenu: boolean = false;
  connected: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router) { 
  }

  ngOnInit() {

    this.checkConnection();
  }

  checkConnection() {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if(user == null || user == undefined){
      this.logout();
    } else {
      console.log(user);
      if(user['platform'] != undefined){
        console.log(user['platform']);
        if(user['platform'] != "URBH"){
          this.logout();
        } else {
          this.connected = true;
          if(user.role === "admin"){
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }
        }
      } else {
        this.logout();
      }
    }
  }

  logout(){
    this.connected = false;
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }
}
